import React, { useState } from "react";
import { Button, Image, Modal } from "antd";
import { getImage } from "../../Services/Api/ApiFile";
import { EyeOutlined } from "@ant-design/icons";

const PreviewImage = ({ src, alt }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  const GetTheImages = async () => {
    try {
      if (src) {
        const res = await getImage(src);
        const blob = res.data;
        if (blob instanceof Blob) {
          const url = URL.createObjectURL(blob);
          setImageUrl(url);
        } else {
          throw new Error("Response is not a Blob");
        }
        setPreviewOpen(true);
      } else {
        setImageUrl(null);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div>
      {src ? (
        <>
          <Button
            type="text"
            icon={<EyeOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              GetTheImages();
            }}
            style={{ cursor: "pointer", padding: 0, height: "auto" }}
          >
            View
          </Button>
          <Modal
            open={previewOpen}
            footer={null}
            onCancel={(e) => {
              e.stopPropagation();
              setPreviewOpen(false);
            }}
          >
            <Image
              src={imageUrl}
              alt={alt}
              preview={false}
              style={{ height: "300px", marginTop: "20px", width: "100%" }}
            />
          </Modal>
        </>
      ) : (
        "No Image"
      )}
    </div>
  );
};

export default PreviewImage;
