import React from "react";
import "./PrivacyPolicy.scss";
import { Typography } from "antd";
const { Paragraph } = Typography;

const PrivacyPolicy = () => {
  return (
    <div className="privacyPolicyMainDiv">
      <Typography.Title level={1} className="TitleText">
        Privacy Policy
      </Typography.Title>
      <div className="privacyContent">
        <Paragraph className="paragraph">
          This privacy policy sets out how Vitran uses and protects any
          information that you give Vitran when you use this website/mobile
          application for Customers and Driver Partners. This Privacy Policy is
          an electronic record between you and Vitran under the Information
          Technology Act, 2000 together with the Rules framed from time to time.
        </Paragraph>
        <Paragraph className="paragraph">
          Your privacy matters to Vitran Technologies Pvt Ltd (the "Company",
          "we", "Vitran", or "us").
        </Paragraph>
        <Paragraph className="paragraph">
          This Privacy Policy ("Policy") describes the policies and procedures
          on the collection, use, disclosure and protection of your information
          when you use our website located at https://vitran.in/, or the mobile
          applications named Vitran and Vitran Delivery App (collectively,
          "Vitran Apps") and is applicable, without limitation, to those
          including customers/service recipients and drivers.
        </Paragraph>
        <Paragraph className="paragraph">
          The terms "you" and "your" refer to the user of the Vitran Apps. The
          term "Services" refers to any services offered by Vitran /You whether
          on the Vitran Apps or otherwise. This Policy is a part of and
          incorporated within, and is to be read along with, the applicable
          Terms of Service and the Driver Partner Terms and Conditions based on
          the user of Vitran.
        </Paragraph>
        <Paragraph className="paragraph">
          Vitran is committed to ensuring that your privacy is protected. Should
          we ask you to provide certain information by which you can be
          identified when using this website, then you can be assured that it
          will only be used in accordance with this privacy statement.
        </Paragraph>
        <Paragraph className="paragraph">
          By using the Vitran Apps and the Services, you agree and consent to
          the collection, transfer, use, storage, disclosure and sharing of your
          information as described and collected by us in accordance with this
          Policy. If you do not agree with the Policy, please do not use or
          access the Vitran Apps.
        </Paragraph>
        <Paragraph className="paragraph">
          Vitran may change this policy from time to time by updating this page.
          The current version of this policy is effective from 19th March, 2024.
          You should check this page from time to time to ensure that you are
          happy with any changes.
        </Paragraph>

        <Typography.Title className="sideheading">
          WHAT WE COLLECT
        </Typography.Title>
        <ul>
          <Paragraph className="paragraph">
            <li>Name and job title.</li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>Contact information including email address.</li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              Demographic information such as postcode, address, mobile number,
              login name, preferences and interests.
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              Use Our Services, we may collect and store information about you
              to process your requests and automatically complete forms for
              future transactions, including (but not limited to) your phone
              number, address, email, billing information, invoices, etc.
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              Other information relevant to customer surveys and/or offers.
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              App install, uninstall and other installed apps information.
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              If you sign up to use our Services as a Driver Partner, we may
              collect location details, profile picture, copies of government
              identification documents and other details (KYC), vehicle related
              documents such as insurance, pollution certificate, bank account
              details, utility bills, registration certificate, driving license,
              etc. and such other documents which evidence the health or fitness
              of the vehicle to provide Services on the Vitran Apps, call and
              SMS details which may be shared with our customer while providing
              the Services.
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              We collect background check and identity verification information
              of the Driver Partner. This may include information such as driver
              history or criminal record (where permitted by law), and right to
              work. This information may be collected by an authorized vendor on
              the Company’s behalf.
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              In case you sign up as a Driver Partner, enable features that
              require Porter’s access to other applications on Your device
              aiming to prevent frauds towards the customers. In case if the
              Driver Partner wishes to participate in the referral program, the
              contact list stored in the phone will be accessed by us post the
              Driver Partner consents to share such selected mobile number. We
              do not intend to share such selected mobile numbers with any third
              party.
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              You may request when you would like to review the information you
              have provided and Vitran shall ensure that any personal
              information or sensitive personal data or information found to be
              inaccurate or deficient shall be corrected or amended as feasible.
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              Vitran shall not retain sensitive personal data or information for
              longer than is required for the purposes for which the information
              may lawfully be used.
            </li>
          </Paragraph>
        </ul>
        <Typography.Title className="sideheading">
          WHAT WE DO WITH THE INFORMATION WE GATHER
        </Typography.Title>
        <Paragraph className="paragraph">
          We require this information to understand your needs and provide you
          with a better service, and in particular for the following reasons:
        </Paragraph>

        <Paragraph className="paragraph">
          <li>
            <b>Internal Record Keeping</b>
          </li>
        </Paragraph>
        <ul style={{ listStyleType: "circle" }}>
          <Paragraph className="paragraph">
            <li>
              We may use the information to improve our Services. We may use
              this information to: Allow you to use Services on the Vitran Apps
              and to carry out our obligations arising from any contracts
              between you and us
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              We may use the information to improve our Services. We may use
              this information to: Allow you to use Services on the Vitran Apps
              and to carry out our obligations arising from any contracts
              between you and us
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              Allow you to undertake or initiate any transactions on the Vitran
              Apps
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              To inform your usage of the Vitran Apps and to manage your account
              with Vitran Apps
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              To process the payments with respect to the transactions which you
              may avail on the Vitran Apps and to perform ancillary Services
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              To respond to your comments. reviews and questions, in order to
              provide customer support and better Services
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              To communicate important notices and changes to the Services
              provided on the Vitran Apps
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              To track the order status, processing, and delivery of Services as
              per your applicability
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>For any other purposes with your consent.</li>
          </Paragraph>
        </ul>
        <Paragraph className="paragraph">
          We may periodically send promotional emails, SMSs and make voice calls
          about new products, special offers or other information which we think
          you may find interesting using the email address and mobile number
          which you have provided.
        </Paragraph>
        <Paragraph className="paragraph">
          From time to time, we may also use your information to contact you for
          market research purposes. We may contact you by email, SMS, voice, fax
          or mail. We may use the information to customize the website according
          to your interests.
        </Paragraph>
        <Paragraph className="paragraph">
          You may specifically opt-out of the above in accordance with the
          Telecom Commercial Communications Customer Preference Regulations,
          2018 or otherwise specified. In case you wish to opt out of the same,
          please contact us at
          <a className="mailTo" href="mailto:hello@vitran.in">
            {" "}
            hello@vitran.in
          </a>
        </Paragraph>

        <Paragraph className="paragraph">
          <li>
            <b>Security</b>
          </li>
        </Paragraph>
        <Paragraph className="paragraph">
          We are committed to ensuring that your information is secure. In order
          to prevent unauthorized access or disclosure we have put in place
          suitable physical, electronic and managerial procedures in accordance
          with the Information Technology (Reasonable security practices and
          procedures and sensitive personal data or information) Rules, 2011
          ("IT RSP Rules") to safeguard and secure the information we collect
          online. We do not retain any information collected from you for any
          longer than is reasonably required by us for the purpose of our
          Services or such period as may be required by applicable laws in
          India. You are required to keep your information and all the other
          data you obtain on the Vitran Apps strictly confidential.
        </Paragraph>

        <Paragraph className="paragraph">
          <li>
            <b>Disclosure</b>
          </li>
        </Paragraph>
        <Paragraph className="paragraph">
          We may disclose to third party services certain personally
          identifiable information listed below:
        </Paragraph>
        <ul style={{ listStyleType: "circle" }}>
          <Paragraph className="paragraph">
            <li>
              Information you provide us such as name, email, mobile phone
              number, language.
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              Information we collect as you access and use our service,
              including device information, location and network carrier. We
              collect precise or approximate location data in foreground when
              the Vitran Apps from the users’ mobile devices if they enable us
              to do so. Vitran collects this data from the time a ride or
              delivery is requested until it is finished, and any time the app
              is running in the foreground of their mobile device. We use this
              data to enhance your use of our apps, including to improve pick-up
              locations, enable safety features, and prevent and detect fraud.
              Vitran collects the Driver Partner’s location data, and links
              location data collected during a trip with their customer’s
              account, even if the customer has not enabled us to collect
              location data from their device. This enables us to offer Services
              to the customers like receipt generation and customer support.
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            This information is shared with third party service providers under
            contracts entered into with them so that we can:
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              Personalize the Vitran Apps for you so you get the smoothest
              experience.
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>Perform behavioural analytics.</li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              Sharing of information with our Vendors, Consultants, Marketing
              Partners, Research Firms and other Service Providers or Business
              Partners such as payment processing entities, to support our
              Services.
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              We may share your information with our academic partners for the
              purposes of carrying out academic research.
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              In case if you are a Driver Partner with Vitran, we may share your
              name, phone number and/or profile picture (if applicable) with
              other users in order to provide them the Services.
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              We may disclose this information with the Governmental Agencies,
              enforcement agencies or other companies assisting us, when we are
              obligated under applicable laws or in good faith or in compliance
              to any orders or processes; or detecting and preventing against
              actual or potential occurrence of identity theft, fraud, abuse of
              Services and other illegal acts.
            </li>
          </Paragraph>
        </ul>
        <Typography.Title className="sideheading">
          PROHIBITED ACTIVITIES
        </Typography.Title>
        <Paragraph className="paragraph">
          As per Rule 3(1)(b) of the Information Technology (Intermediary
          Guidelines and Digital Media Ethics Code) Rules, 2021, you shall not
          host, display, upload, modify, publish, transmit, store, update or
          share any information that:
        </Paragraph>
        <ul>
          <Paragraph className="paragraph">
            <li>
              Wbelongs to another person and to which the user does not have any
              right
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              is defamatory, obscene, pornographic, paedophilic, invasive of
              another’s privacy including bodily privacy, insulting or harassing
              on the basis of gender, libellous, racially or ethnically
              objectionable, relating or encouraging money laundering or
              gambling, or otherwise inconsistent with or contrary to the laws
              in force
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>is harmful to child</li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              infringes any patent, trademark, copyright or other proprietary
              rights
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>violates any law for the time being in force</li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              deceives or misleads the addressee about the origin of the message
              or knowingly and intentionally communicates any information which
              is patently false or misleading in nature but may reasonably be
              perceived as a fact
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>impersonates another person</li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              threatens the unity, integrity, defence, security or sovereignty
              of India, friendly relations with foreign States, or public order,
              or causes incitement to the commission of any cognisable offence
              or prevents investigation of any offence or is insulting other
              nation
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              contains software virus or any other computer code, file or
              program designed to interrupt, destroy or limit the functionality
              of any computer resource
            </li>
          </Paragraph>
          <Paragraph className="paragraph">
            <li>
              is patently false and untrue, and is written or published in any
              form, with the intent to mislead or harass a person, entity or
              agency for financial gain or to cause any injury to any person.
            </li>
          </Paragraph>
        </ul>
        <Paragraph className="paragraph">
          In case of non-compliance with rules and regulations, Policy or the
          terms of service, we have the right to terminate the access or usage
          rights of the users to the computer resource or the Vitran Apps
          immediately or remove non-compliant information or both, as the case
          may be.
        </Paragraph>

        <Typography.Title className="sideheading">
          WITHDRAWAL OR NON-PROVISION OF DATA
        </Typography.Title>
        <Paragraph className="paragraph">
          In case of non-compliance with rules and regulations, Policy or the
          terms of service, we have the right to terminate the access or usage
          rights of the users to the computer resource or the Vitran Apps
          immediately or remove non-compliant information or both, as the case
          may be.
        </Paragraph>
        <Typography.Title className="sideheading">
          HOW WE USE COOKIES
        </Typography.Title>
        <Paragraph className="paragraph">
          A cookie is a small file which asks permission to be placed on your
          computer's hard drive. Once you agree, the file is added and the
          cookie helps analyze web traffic or lets you know when you visit a
          particular site. Cookies allow web applications to respond to you as
          an individual. The web application can tailor its operations to your
          needs, likes and dislikes by gathering and remembering information
          about your preferences.
        </Paragraph>
        <Paragraph className="paragraph">
          We use traffic log cookies to identify which pages are being used.
          This helps us analyze data about webpage traffic and improve our
          website in order to tailor it to customer needs. We only use this
          information for statistical analysis purposes and then the data is
          removed from the system.
        </Paragraph>
        <Paragraph className="paragraph">
          Overall, cookies help us provide you with a better website, by
          enabling us to monitor which pages you find useful and which you do
          not. A cookie in no way gives us access to your computer or any
          information about you, other than the data you choose to share with
          us.
        </Paragraph>
        <Paragraph className="paragraph">
          You can choose to accept or decline cookies. Most web browsers
          automatically accept cookies, but you can usually modify your browser
          setting to decline cookies if you prefer. This may prevent you from
          taking full advantage of the website and/or Vitran Apps.
        </Paragraph>
        <Typography.Title className="sideheading">
          LINK TO OTHER WEBSITES
        </Typography.Title>
        <Paragraph className="paragraph">
          Our website may contain links to other websites of interest. However,
          once you have used these links to leave our site, you should note that
          we do not have any control over that other website which may also be
          in form of a banner advertisement. We do not sell, share, rent or
          trade the information we have collected about you, other than as
          disclosed within this Privacy Policy or at the time you provide your
          information. Therefore, we cannot be responsible for the protection
          and privacy of any information which you provide whilst visiting such
          sites and such sites are not governed by this privacy statement. You
          should exercise caution and look at the privacy statement applicable
          to the website in question and may contact that third party directly.
        </Paragraph>
        <Paragraph className="paragraph">
          We will not disclose or share your personal information to third
          parties unless we have your permission or are required by law to do
          so. We may use your personal information to send you promotional
          information about third parties which we think you may find
          interesting if you tell us that you wish this to happen
        </Paragraph>
        <Paragraph className="paragraph">
          You may request details of personal information which we hold about
          you under the Information Technology (Reasonable security practices
          and procedures and sensitive personal data or information) Rules,
          2011. If you would like a copy of the information held on you please
          contact us.
        </Paragraph>
        <Paragraph className="paragraph">
          If you believe that any information we are holding on you is incorrect
          or incomplete, please write to or email us as soon as possible, at the
          above address. We will promptly correct any information found to be
          incorrect.
        </Paragraph>
        <Typography.Title className="sideheading">CONTACT US</Typography.Title>
        <Paragraph className="paragraph">
          If there are any questions regarding this privacy policy or if you
          wish to report a breach of the Privacy Policy, you may contact us
          using the information on the support page.
        </Paragraph>
        <Typography.Title className="sideheading">
          GRIEVANCE OFFICER
        </Typography.Title>
        <Paragraph className="paragraph">
          In accordance with the Information Technology Act, 2000 (including
          amendments there to) and rules made there under, the Contact details
          of the Grievance Officer are provided below, if you have queries or
          concerns regarding this privacy policy only, you may reach out
          directly in the following details: Legal Team, Vitran Technologies Pvt
          Ltd Email Id:{" "}
          <a className="mailTo" href="mailTo:legal@vitran.in">
            legal@vitran.in
          </a>
          Time: Monday to Friday (10 a.m. to 6 p.m.) For any queries or concerns
          regarding our Services, kindly email us at{" "}
          <a className="mailTo" href="mailTo:hello@vitran.in">
            hello@vitran.in
          </a>
        </Paragraph>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
