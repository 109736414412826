import { Empty, Typography } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";

const NoData = () => {
  const location = useLocation();
  const pathMessages = {
    "/deliveryRequest": "No Delivery Requests",
    "/assignDA": "No Orders To Assign",
  };

  const message = pathMessages[location.pathname] || "No Orders Found";

  return (
    <div>
      <Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{
          height: 60,
        }}
        description={<Typography.Text>{message}</Typography.Text>}
      />
    </div>
  );
};

export default NoData;
