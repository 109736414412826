import React, { useState } from "react";
import {
  ContainerOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Button, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { MdAssignmentTurnedIn } from "react-icons/md";
import { TbTruckDelivery } from "react-icons/tb";
import { TbFileSpreadsheet } from "react-icons/tb";
import { IoCashOutline } from "react-icons/io5";
import { RiDiscountPercentLine } from "react-icons/ri";
import { IoImagesOutline } from "react-icons/io5";
const token = JSON.parse(localStorage.getItem("token"));

const memberItems = [
  {
    key: "/deliveryRequest",
    icon: <TbTruckDelivery />,
    label: <Link to="/deliveryRequest">Delivery Request</Link>,
  },
  {
    key: "/assignDA",
    icon: <MdAssignmentTurnedIn />,
    label: <Link to="/assignDA">Assign To DA</Link>,
  },
  {
    key: "/orderStatus",
    icon: <ContainerOutlined />,
    label: <Link to="/orderStatus">Order History</Link>,
  },
  {
    key: "/runSheet",
    icon: <TbFileSpreadsheet />,
    label: <Link to="/runSheet">Run Sheet</Link>,
  },
  {
    key: "/DaCashReconciliation",
    icon: <IoCashOutline />,
    label: <Link to="/DaCashReconciliation">Cash Reconciliation</Link>,
  },
  {
    key: "/cashbackList",
    icon: <RiDiscountPercentLine />,
    label: <Link to="/cashbackList">Cashback List</Link>,
  },
  {
    key: "/bannerImages",
    icon: <IoImagesOutline />,
    label: <Link to="/bannerImages">Banner Images</Link>,
  },
];

const deliveryAssociateItems = [
  {
    key: "/orderList",
    icon: <ContainerOutlined />,
    label: <Link to="/orderList">Order List</Link>,
  },
];

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  const items = token?.role === "member" ? memberItems : deliveryAssociateItems;

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className="sidebar">
      <Button
        type="primary"
        onClick={toggleCollapsed}
        style={{
          marginBottom: 16,
          backgroundColor: "#ff6a1a",
        }}
      >
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button>
      <Menu
        // defaultSelectedKeys={["1"]}
        selectedKeys={[location.pathname]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        theme="light"
        inlineCollapsed={collapsed}
        items={items}
        style={{ backgroundColor: "#f5f3f7" }}
      />
    </div>
  );
};

export default Sidebar;
