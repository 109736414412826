import React from "react";

const Offline = () => (
  <div
    style={{
      textAlign: "center",
      backgroundColor: "#f8f8f8",
      width: "100%",
    }}
  >
    <h1 style={{ fontSize: "2em", color: "#333" }}>Internet Connection Lost</h1>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        boxSizing: "border-box",
        width: "100%",
      }}
    >
      <img style={{ width: "55%" }} src="noInternet.png" alt="noInternet" />
    </div>
    <p style={{ fontSize: "1.2em", color: "#666" }}>
      Please go online to continue using the app.
    </p>
  </div>
);

export default Offline;
