import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <div>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      <ToastContainer />
    </div>
  </>
);

serviceWorkerRegistration.register();

reportWebVitals();

// older url : http://64.227.137.187:8080/roverapi/v1/
