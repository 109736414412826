import React, { useState } from "react";
import { Upload, Button, Checkbox, Form, message, Modal } from "antd";
import { useFormik } from "formik";
import * as yup from "yup";
import { UploadOutlined } from "@ant-design/icons";
import Resizer from "react-image-file-resizer";
import { UploadBannerImages } from "../../Services/Api/ApiFile";

const UploadImages = () => {
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const schema = yup.object({
    image: yup
      .mixed()
      .required("Image is required")
      .test("fileFormat", "Only jpg or png allowed", (value) => {
        if (value instanceof Blob) {
          return ["image/jpeg", "image/png"].includes(value.type);
        }
        return false;
      }),
    status: yup.boolean().required("This Field is Required"),
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetForm();
    setPreviewUrl(null);
  };

  const resizeImage = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600, // max width
        600, // max height
        "JPEG",
        100, // quality
        0, // rotation
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });

  const { values, errors, touched, handleSubmit, setFieldValue, resetForm } =
    useFormik({
      initialValues: {
        image: null,
        status: false,
      },
      validationSchema: schema,
      onSubmit: async (values) => {
        console.log("values", values);
        try {
          const formData = new FormData();
          formData.append("status", values.status);
          // Ensure the image is appended as a Blob
          formData.append("image", values.image);
          const res = await UploadBannerImages(formData);
          console.log(formData);
          if (res) {
            message.success("Upload successful");
            handleCancel();
          } else {
            message.error("Upload failed");
          }
        } catch (error) {
          message.error("An error occurred");
        }
      },
    });

  const handleImageUpload = async ({ file }) => {
    try {
      const resizedImage = await resizeImage(file);
      setFieldValue("image", resizedImage);

      // Create a preview URL for the resized image
      const previewUrl = URL.createObjectURL(resizedImage);
      setPreviewUrl(previewUrl);
    } catch (error) {
      console.error("Error resizing image:", error);
      message.error("Error resizing image");
    }
  };

  return (
    <div>
      <Button onClick={showModal}>Upload Banner Images</Button>
      <Modal
        title={
          <p
            style={{
              textAlign: "center",
              fontSize: "20px",
              margin: 0,
              padding: 0,
            }}
          >
            Create New Banner Image
          </p>
        }
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
      >
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Form.Item
            validateStatus={errors.image ? "error" : ""}
            help={errors.image}
          >
            <Upload
              beforeUpload={() => false}
              onChange={handleImageUpload}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Select Image</Button>
            </Upload>
          </Form.Item>
          {previewUrl && (
            <img
              src={previewUrl}
              alt="Preview"
              style={{
                marginBottom: "1rem",
                height: "100px",
              }}
            />
          )}
          <Form.Item
            help={errors.status && touched.status ? errors.status : null}
            validateStatus={errors.status && touched.status ? "error" : ""}
          >
            <Checkbox
              checked={values.status}
              onChange={(e) => setFieldValue("status", e.target.checked)}
              name="status"
              id="status"
            >
              Status
            </Checkbox>
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </form>
      </Modal>
    </div>
  );
};

export default UploadImages;
