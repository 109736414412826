import { Button, Card, DatePicker, Select, Table, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import "./DaCashReconciliation.scss";
import { LoadingContext } from "../../components/Loader/LoadingContext";
import dayjs from "dayjs";
import {
  GetDACashReconcileList,
  StationDeliveryAssociates,
} from "../../Services/Api/ApiFile";
import { useNavigate } from "react-router-dom";
import { json2csv } from "json-2-csv";
import FileSaver from "file-saver";
import { GrDocumentCsv } from "react-icons/gr";
const { Paragraph } = Typography;

const DaCashReconciliation = () => {
  const [daCashReconcileData, setDaCashReconcileData] = useState([]);
  const [selectDate, setSelectDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [status, setStatus] = useState("Pending");
  const [deliveryAssistants, setDeliveryAssistants] = useState([]);
  const [selectedDA, setSelectedDA] = useState({ username: "All", id: "All" });
  const [filteredData, setFilteredData] = useState([]);
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const navigate = useNavigate("");
  const dateFormatList = ["DD-MM-YYYY", "DD-MM-YY", "DD-MM-YYYY", "DD-MM-YY"];
  // console.log(selectDate, selectedDA);
  const columns = [
    {
      title: "DA Name",
      dataIndex: "assignPersonName",
      key: "assignPersonName",
      responsive: ["xs", "sm", "md", "lg", "xl"],
      render: (text, record) => <div>{text ? text : "N.A"}</div>,
    },

    // {
    //   title: "Date",
    //   dataIndex: "deliveryDate",
    //   key: "deliveryDate",
    //   responsive: ["md", "lg", "xl"],
    //   render: (text, record) => <div>{dayjs(text).format("DD-MM-YYYY")}</div>,
    // },
    {
      title: "Expected Cash",
      dataIndex: "deliveryCharges",
      key: "deliveryCharges",
      responsive: ["xs", "sm", "md", "lg", "xl"],
      render: (text, record) => <div>{text ? text : "N.A"}</div>,
    },
    {
      title: "Cash Collected",
      dataIndex: "collectedAmount",
      key: "collectedAmount",
      responsive: ["xs", "sm", "md", "lg", "xl"],
      render: (text, record) => <div>{text ? text : "N.A"}</div>,
    },
    {
      title: "Cash Deposited",
      dataIndex: "depositedAmount",
      key: "depositedAmount",
      responsive: ["xs", "sm", "md", "lg", "xl"],
      render: (text, record) => <div>{text ? text : "N.A"}</div>,
    },
    {
      title: "Variance",
      dataIndex: "variance",
      key: "variance",
      responsive: ["xs", "sm", "md", "lg", "xl"],
      render: (text, record) => (
        <div>{record.collectedAmount - record.depositedAmount}</div>
      ),
    },
  ];
  const onChange = (value) => {
    if (value) {
      const parsedValue = JSON.parse(value);
      setSelectedDA(parsedValue);
    } else {
      setSelectedDA({});
    }
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };
  const GetDeliveryAssistants = async () => {
    try {
      const res = await StationDeliveryAssociates();
      // console.log(res.data);
      setDeliveryAssistants([{ username: "All", id: "All" }, ...res.data]);
    } catch (error) {
      console.log(error.message);
    }
  };

  const GetCashReconcileData = async () => {
    try {
      startLoading();
      const res = await GetDACashReconcileList(
        selectedDA.id,
        selectDate,
        status
      );
      // console.log(res.data);
      setDaCashReconcileData(res.data);
    } catch (error) {
    } finally {
      stopLoading();
    }
  };

  const handleDownloadCSV = async () => {
    const dataToConvert = daCashReconcileData.map((el) => ({
      AssignPersonId: el.assignPersonId,
      AssignPersonName: el.assignPersonName,
      TotalAmount: el.deliveryCharges,
      CollectedAmount: el.collectedAmount,
      DepositedAmount: el.depositedAmount,
      Variance: el.collectedAmount - el.depositedAmount,
    }));

    const options = {
      keys: [
        "AssignPersonId",
        "AssignPersonName",
        "TotalAmount",
        "CollectedAmount",
        "DepositedAmount",
        "Variance",
      ],
      delimiter: {
        wrap: '"', // Optional wrapping of values
        field: ",", // Field delimiter
        array: ";", // Array value delimiter
      },
    };

    try {
      const csv = json2csv(dataToConvert, options);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      FileSaver.saveAs(blob, "cashReconcile.csv");
    } catch (error) {
      console.error("Error generating CSV:", error);
    }
  };

  useEffect(() => {
    GetCashReconcileData();
    GetDeliveryAssistants();
  }, [status, selectDate, selectedDA]);

  // useEffect(() => {
  //   let filtered = DaCashReconcileData;

  //   if (selectedDA.username) {
  //     filtered = filtered.filter(
  //       (item) => item.assignPersonName === selectedDA.username
  //     );
  //   }

  //   if (selectDate) {
  //     filtered = filtered.filter(
  //       (item) => dayjs(item.deliveryDate).format("YYYY-MM-DD") === selectDate
  //     );
  //   }

  //   setFilteredData(filtered);
  // }, [selectedDA, selectDate, DaCashReconcileData]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Typography.Title level={3} className="heading">
          DA Cash Reconciliation
        </Typography.Title>
        <Button className="downloadCSVbtn" onClick={handleDownloadCSV}>
          Download
          <GrDocumentCsv style={{ fontSize: "20px" }} />
        </Button>
      </div>
      <Card style={{ marginTop: "10px" }}>
        <Card
          style={{
            backgroundColor: "rgba(244, 109, 37, 0.12)",
          }}
        >
          <Paragraph style={{ textAlign: "center", fontWeight: 600 }}>
            Delivery Assistants list
          </Paragraph>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Select
              allowClear
              showSearch
              placeholder="Select a person"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              style={{ width: "50%" }}
              defaultValue={"All"}
            >
              {deliveryAssistants.map((el, id) => (
                <Select.Option
                  key={id}
                  value={JSON.stringify({
                    username: el.username,
                    id: el.id,
                  })}
                >
                  {el.username}
                </Select.Option>
              ))}
            </Select>
            <Button
              iconPosition={"start"}
              onClick={() => {
                if (selectedDA.id !== "All" && selectedDA.id) {
                  navigate(
                    `/individualDaCashReconcile/${selectedDA.id}/${selectedDA.username}`
                  );
                }
              }}
            >
              Get DA Data
            </Button>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <DatePicker
              id="SelectDate"
              name="SelectDate"
              defaultValue={dayjs()}
              // value={dayjs(selectDate)}
              onChange={(date) => {
                if (date) {
                  setSelectDate(dayjs(date).format("YYYY-MM-DD"));
                } else {
                  setSelectDate(null);
                }
              }}
              placeholder="Select Date"
              format={dateFormatList}
              style={{ width: "45%" }}
            />

            <Select
              name="Status"
              id="Status"
              defaultValue="Pending"
              onChange={(value) => setStatus(value)}
              style={{ width: "45%" }}
            >
              {[
                { value: "Pending", name: "Pending" },
                { value: "All", name: "All" },
              ].map((status, id) => (
                <Select.Option key={id} value={status.value}>
                  {status.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Card>
        <Table
          rowKey="assignPersonId"
          columns={columns}
          dataSource={daCashReconcileData}
          pagination={{ pageSize: 6 }}
        />
      </Card>
    </div>
  );
};

export default DaCashReconciliation;
