import React from "react";
import footerImg from "../../../images/footer.png";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
const { Paragraph } = Typography;

const HomeFooter = () => {
  const navigate = useNavigate();
  return (
    <div className="footerMaindiv">
      <div className="footerImagediv">
        <img src={footerImg} alt="FooterImg" />
      </div>
      <div className="footerAddress">
        <div className="address">
          <Typography.Title level={4} className="addressTitle">
            Address
          </Typography.Title>
          <Typography.Title level={5} className="addressDetails">
            Second Floor, 1, GMS Road Nehru Enclave, Satya Vihar, Ballupur
            Dehradun, Uttarakhand 248001
          </Typography.Title>
        </div>
        <div className="contactdiv">
          <Typography.Title level={4} className="contactus">
            Contact-us
          </Typography.Title>
          <Paragraph className="mail">
            <a href="mailto:HELLO@VITRAN.IN"> Hello@vitran.in</a>
          </Paragraph>
          <Paragraph className="mail">
            <a href="mailto:HELLO@SAKSHAMHUB.COM">Hello@saksham.com</a>
          </Paragraph>
        </div>
      </div>
      <div className="endContent">
        <Typography.Title
          level={4}
          className="privacypolicy"
          onClick={() => navigate("/privacyPolicy")}
        >
          Privacy policy
        </Typography.Title>
        <Paragraph className="copyright">
          Copyright ©2024 | Vitran Technologies Pvt Ltd
        </Paragraph>
      </div>
    </div>
  );
};

export default HomeFooter;
