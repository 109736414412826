import React, { useContext, useEffect, useState } from "react";
import {
  ActiveDeactiveApi,
  GetBannerImagesList,
} from "../../Services/Api/ApiFile";
import UploadImages from "./UploadImages";
import { Card, message, Popconfirm, Switch, Table, Typography } from "antd";
import PreviewImage from "../../components/PreviewImage/PreviewImage";
import { LoadingContext } from "../../components/Loader/LoadingContext";
import { DeleteOutlined } from "@ant-design/icons";

const BannerImagesList = () => {
  const [bannerImagesList, setBannerImagesList] = useState([]);
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      responsive: ["xs", "sm", "md", "lg", "xl"],
      render: (text, record) => (
        <div>
          <PreviewImage src={text} alt="bannerImage" />
        </div>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      responsive: ["xs", "sm", "md", "lg", "xl"],
      render: (text, record) => (
        <div>
          <Switch
            checked={text}
            onChange={(checked) => handleActiveDeactiveApi(record.id, checked)}
            style={{ backgroundColor: text ? "#ff6a1a" : undefined }}
          />
        </div>
      ),
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   responsive: ["xs", "sm", "md", "lg", "xl"],
    //   render: (text, record) => (
    //     <div style={{ textAlign: "center" }}>
    //       <Popconfirm
    //         title="Do you want to delete this banner?"
    //         // onConfirm={() => handleDeleteBanner(record.id)}
    //         okText="Yes"
    //         cancelText="No"
    //       >
    //         <DeleteOutlined
    //           style={{ cursor: "pointer", color: "red", fontSize: "18px" }}
    //         />
    //       </Popconfirm>
    //     </div>
    //   ),
    // },
  ];

  const handleActiveDeactiveApi = async (id, status) => {
    try {
      const res = await ActiveDeactiveApi(id);
      if (res.status === 200) {
        message.success("Banner image status updated successfully");
        GetBannerImagesApi();
      } else {
        message.error("Failed to update banner image status");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const GetBannerImagesApi = async () => {
    try {
      startLoading();
      const res = await GetBannerImagesList();
      setBannerImagesList(res.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    GetBannerImagesApi();
  }, []);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Typography.Title level={3} className="heading">
          Banner Images List
        </Typography.Title>
        <UploadImages />
      </div>
      <Card style={{ marginTop: "10px" }}>
        <Table
          columns={columns}
          dataSource={bannerImagesList}
          rowKey="id"
          pagination={{ pageSize: 6 }}
          className="runsheet-table"
          //   onRow={(record) => ({
          //     onClick: () => {
          //       console.log(record);
          //     },
          //   })}
        />
      </Card>
    </div>
  );
};

export default BannerImagesList;
