import { Col, Image, Row, Typography } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import ThreeWheeler from "../../images/three wheeler 1.png";
import TwoWheeler from "../../images/motorcycle 1.png";
import FourWheeler from "../../images/express-delivery 1.png";
import "./OrderStatus.scss";
import NoData from "../../components/NoData/NoData";
import PreviewImage from "../../components/PreviewImage/PreviewImage";
import OrderTrackingHistory from "./OrderTrackingHistory";
const { Paragraph } = Typography;

const DeliveryStatusTable = ({ deliveryOrderStatus, orderStatus }) => {
  const [isOrderTrackingModalOpen, setIsOrderTrackingModalOpen] =
    useState(false);
  const [selectedOrderData, setSelectedOrderData] = useState({});
  const handleOpenOrderTracking = async (order) => {
    // console.log(" selecsdelivery", order);
    setSelectedOrderData(order);
    setIsOrderTrackingModalOpen(true);
  };

  const handleCancelOrderTracking = () => {
    setIsOrderTrackingModalOpen(false);
  };
  return (
    <div className="orderDeliveryReq-mainBox">
      {deliveryOrderStatus.length === 0 ? (
        <NoData />
      ) : (
        deliveryOrderStatus.map((el, index) => {
          return (
            <div key={index}>
              <Row
                style={{
                  backgroundColor:
                    el?.deliverystatus === "Delivered"
                      ? "rgba(0, 201, 76, 0.12)"
                      : el?.deliverystatus === "Cancelled"
                      ? "rgba(78, 78, 78, 0.12)"
                      : "rgba(244, 109, 37, 0.12)",
                  borderRadius: "5px",
                  marginTop: index === 0 ? 0 : "10px",
                  padding: "10px",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenOrderTracking(el);
                }}
              >
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Typography.Text style={{ margin: 0, fontWeight: 600 }}>
                    {el.orderId}{" "}
                    {orderStatus === "All" && ` - ${el.deliverystatus}`}{" "}
                    {el.type}
                  </Typography.Text>
                </Col>
                <Col
                  className="nameAndNumber"
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Created At
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {dayjs(el.createdAt).format("DD-MM-YYYY")}
                  </Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Weight
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {el.weight} {el.units}
                  </Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Customer Number
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {el.customerMobileNo}
                  </Paragraph>
                </Col>
                <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Pickup Date
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {dayjs(el.pickupDate).format("DD-MM-YYYY")}
                  </Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Pickup Slot
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>{el.pickupSlot}</Paragraph>
                  {el.deliverystatus === "Delivered" && (
                    <div>
                      <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                        PickUp Image
                      </Paragraph>
                      <PreviewImage src={el.pickupImage} alt="Pickup" />
                    </div>
                  )}
                </Col>
                <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Delivery Date
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {dayjs(el.deliveryDate).format("DD-MM-YYYY")}
                  </Paragraph>
                  <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                    Delivery Slot
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    {el.deliveryTimeSlot}
                  </Paragraph>
                  {el.deliverystatus === "Delivered" && (
                    <div>
                      <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                        Drop Image
                      </Paragraph>
                      <PreviewImage src={el.deliveryImage} alt="DropImage" />
                    </div>
                  )}
                </Col>

                <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                  <div>
                    {el.assignPersonName && (
                      <div>
                        <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                          Assigned To
                        </Paragraph>
                        <Paragraph style={{ margin: 0 }}>
                          {el.assignPersonName}
                        </Paragraph>
                      </div>
                    )}
                  </div>
                  {el.vehicleType === "Three Wheeler" && (
                    <div>
                      <Image src={ThreeWheeler} alt="icon" preview={false} />
                    </div>
                  )}
                  {el.vehicleType === "Two Wheeler" && (
                    <div>
                      <Image src={TwoWheeler} alt="icon" preview={false} />
                    </div>
                  )}
                  {el.vehicleType === "Four Wheeler" && (
                    <div>
                      <Image src={FourWheeler} alt="icon" preview={false} />
                    </div>
                  )}
                  {el.type === "vendor" && (
                    <div>
                      <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                        Vendor code
                      </Paragraph>
                      <Paragraph style={{ margin: 0 }}>
                        {el.vendorCode}
                      </Paragraph>
                    </div>
                  )}
                  {el.type === "customer" && (
                    <div>
                      <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                        Customer Name
                      </Paragraph>
                      <Paragraph style={{ margin: 0 }}>
                        {el.customerName}
                      </Paragraph>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          );
        })
      )}

      <OrderTrackingHistory
        isOrderTrackingModalOpen={isOrderTrackingModalOpen}
        selectedOrderData={selectedOrderData}
        handleCancelOrderTracking={handleCancelOrderTracking}
      />
    </div>
  );
};

export default DeliveryStatusTable;
