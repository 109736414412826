import React from "react";
import { Spin } from "antd";
import "./Loader.scss";

const contentStyle = {
  padding: 50,
  background: "rgba(0, 0, 0, 0.05)",
  borderRadius: 4,
};
const content = <div style={contentStyle} />;
const Loader = ({ loading }) => {
  return loading ? (
    <div className="loader-container">
      <Spin tip="Loading..." size="large">
        {content}
      </Spin>
    </div>
  ) : null;
};

export default Loader;
