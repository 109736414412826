import React, { useContext, useEffect, useState } from "react";
import { LoadingContext } from "../../components/Loader/LoadingContext";
import { GetOrderTrackingHistory } from "../../Services/Api/ApiFile";
import { Modal, Steps, Typography } from "antd";
import dayjs from "dayjs";
const { Paragraph } = Typography;
const { Step } = Steps;

const OrderTrackingHistory = ({
  isOrderTrackingModalOpen,
  selectedOrderData,
  handleCancelOrderTracking,
}) => {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [stepData, setStepData] = useState([]);
  const OrderTrackingStatus = async () => {
    try {
      startLoading();
      const res = await GetOrderTrackingHistory(selectedOrderData?.orderId);
      console.log(res.data);
      setStepData(res.data);
    } catch (err) {
      console.log(err.message);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    if (selectedOrderData.orderId) {
      OrderTrackingStatus();
    }
  }, [selectedOrderData.orderId]);
  return (
    <div>
      <Modal
        title={
          <p
            style={{
              textAlign: "center",
              fontSize: "20px",
              margin: 0,
              padding: 0,
            }}
          >
            Order Tracking
          </p>
        }
        open={isOrderTrackingModalOpen}
        onCancel={(e) => {
          e.stopPropagation();
          handleCancelOrderTracking();
        }}
        footer={false}
      >
        <Paragraph style={{ textAlign: "center" }}>
          Order Id: <b>{selectedOrderData.orderId}</b>
        </Paragraph>
        <Steps direction="vertical" current={stepData.length - 1}>
          {stepData.map((step, index) => (
            <Step
              key={index}
              title={step.deliveryStatus}
              description={
                <div style={{ margin: 0 }}>
                  <Paragraph style={{ margin: 0 }}>
                    Action Date:{" "}
                    {dayjs(step.actionDateTime).format("DD-MM-YYYY")}
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    Action Time: {dayjs(step.actionDateTime).format("h:mm A")}
                  </Paragraph>
                  <Paragraph style={{ margin: 0 }}>
                    Action By: {step.actionby}
                  </Paragraph>
                </div>
              }
            />
          ))}
        </Steps>
      </Modal>
    </div>
  );
};

export default OrderTrackingHistory;
