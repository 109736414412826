import React from "react";
import "./Navbar.scss";
import {
  CloseOutlined,
  DownOutlined,
  MenuOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Dropdown, Image, message, Space } from "antd";

const Navbar = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const navigate = useNavigate();
  const location = useLocation();
  const onClick = ({ key }) => {
    message.info(`Clicked on ${key}`);
    if (key === "Sign out") {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("username");
      navigate("/");
    }
  };

  const handleLinkClick = () => {
    const checkbox = document.getElementById("sidebar-active");
    if (checkbox) {
      checkbox.checked = false;
    }
  };

  const items = token
    ? [
        {
          label: "Profile",
          key: "Profile",
        },
        {
          label: "Sign out",
          key: "Sign out",
        },
      ]
    : [
        {
          label: "Sign in",
          key: "Sign in",
        },
      ];

  const isActiveLink = (path) => {
    return location.pathname === path ? "active-link" : "";
  };
  return (
    <div>
      <nav className="content-navbar">
        <Link to="/" className="head-Image">
          <Image src="/rover-logo.svg" alt="icon" preview={false} />
        </Link>
        <input type="checkbox" id="sidebar-active" />
        <label htmlFor="sidebar-active" className="open-sidebar-button">
          <MenuOutlined />
        </label>
        <div className="links-container">
          <label htmlFor="sidebar-active" className="close-sidebar-button">
            <CloseOutlined />
          </label>
          {token ? (
            <Link
              to="/"
              onClick={() => {
                onClick({ key: "Sign out" });
                handleLinkClick();
              }}
            >
              Sign Out
            </Link>
          ) : (
            <Link
              to="/login"
              className={isActiveLink("/login")}
              onClick={handleLinkClick}
            >
              SignIn
            </Link>
          )}
          {token && token.role === "member" && (
            <>
              <Link
                to="/deliveryRequest"
                className={isActiveLink("/deliveryRequest")}
                onClick={handleLinkClick}
              >
                Delivery Request
              </Link>
              <Link
                to="/assignDA"
                className={isActiveLink("/assignDA")}
                onClick={handleLinkClick}
              >
                Assign To DA
              </Link>
              <Link
                to="/orderStatus"
                className={isActiveLink("/orderStatus")}
                onClick={handleLinkClick}
              >
                Order History
              </Link>
              <Link
                to="/runSheet"
                className={isActiveLink("/runSheet")}
                onClick={handleLinkClick}
              >
                Run Sheet Closure
              </Link>
              <Link
                to="/DaCashReconciliation"
                className={isActiveLink("/DaCashReconciliation")}
                onClick={handleLinkClick}
              >
                DA Cash Reconciliation
              </Link>
              <Link
                to="/cashbackList"
                className={isActiveLink("/cashbackList")}
                onClick={handleLinkClick}
              >
                Cashback List
              </Link>
              <Link
                to="/bannerImages"
                className={isActiveLink("/bannerImages")}
                onClick={handleLinkClick}
              >
                Banner Images
              </Link>
            </>
          )}
          {token && token.role === "delivery associate" && (
            <Link
              to="/orderList"
              className={isActiveLink("/orderList")}
              onClick={handleLinkClick}
            >
              Order List
            </Link>
          )}
          {/* <div className="dropdown-mobile">
            {items.map((item) => (
              <Link to={`/${item.key}`} key={item.key}>
                {item.label}
              </Link>
            ))}
          </div> */}
        </div>
        <div className="dropdown-desktop">
          <Dropdown
            overlayClassName="dropdown-overlay"
            menu={{
              items,
              onClick,
            }}
          >
            <Button
              onClick={(e) => e.preventDefault()}
              className="dropdown-link"
              style={{ border: "none", backgroundColor: "#f5f3f7" }}
            >
              <Space>
                <UserOutlined style={{ fontSize: "21px" }} />
                <DownOutlined style={{ fontSize: "12px" }} />
              </Space>
            </Button>
          </Dropdown>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
