// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// vapidkey="BPGeBhTQiAinpKZMYJ1IcMzoHuPxWbMMU9VIyxUeXF7YtKuaEaDO_F_Mkc6d9SgrFSgxJfiWjxAvG_ZA9451RCwSep 2, 2024"
const firebaseConfig = {
  apiKey: "AIzaSyADB0KL27E18LZ3ui7ErFbonE2xjMvVJ9k",
  authDomain: "first-cloud-messaging-e10c9.firebaseapp.com",
  projectId: "first-cloud-messaging-e10c9",
  storageBucket: "first-cloud-messaging-e10c9.appspot.com",
  messagingSenderId: "272932832566",
  appId: "1:272932832566:web:2c4f982e5a29c45daf9f97",
  measurementId: "G-EHSBN8VYFM",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const requestForToken = async () => {
  console.log("reqfortoken called");
  if (Notification.permission === "granted") {
    await getToken(messaging, {
      vapidKey:
        "BPGeBhTQiAinpKZMYJ1IcMzoHuPxWbMMU9VIyxUeXF7YtKuaEaDO_F_Mkc6d9SgrFSgxJfiWjxAvG_ZA9451RCw",
    })
      .then((currentToken) => {
        if (currentToken) {
          console.log("FCM Token:", currentToken);
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
      });
  }
};

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       console.log("payload", payload);
//       resolve(payload);
//     });
//   });
