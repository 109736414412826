import React from "react";
import DeliveryMap from "../../../images/vitranDeliverymap.png";
import { Typography } from "antd";
import "../../HomePage.scss";
import CountUp from "react-countup";

const DeliveringStatesMap = () => {
  return (
    <div className="deliveringStatusMapMainBox">
      <div className="leftside">
        <Typography.Title level={1} className="title">
          WE HAVE DEEP PRESENCE IN{" "}
          <span style={{ color: "#ff6a1a" }}>NORTH INDIA</span>
        </Typography.Title>
        <div className="availableMainBox">
          <div>
            <Typography.Title level={1} className="avaialabity">
              <CountUp start={0} end={6} duration={1.5} />
            </Typography.Title>
            <Typography.Text className="text">States</Typography.Text>
          </div>
          <div>
            <Typography.Title level={1} className="avaialabity">
              <CountUp start={0} end={60} duration={2.5} />+
            </Typography.Title>
            <Typography.Text className="text">Towns & Cities</Typography.Text>
          </div>
          <div>
            <Typography.Title level={1} className="avaialabity">
              <CountUp start={0} end={30000} duration={3.5} />
            </Typography.Title>
            <Typography.Text className="text">Daily Shipments</Typography.Text>
          </div>
        </div>
      </div>
      <div className="Rightsidemap">
        <img src={DeliveryMap} alt="Map" />
      </div>
    </div>
  );
};

export default DeliveringStatesMap;
