import { Form, Input, message, Modal, Typography } from "antd";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { useEffect } from "react";
import { UpdateDepositAmountByDA } from "../../Services/Api/ApiFile";
const { Paragraph } = Typography;

const CashReconcileModal = ({
  selectedDaData,
  isModalOpen,
  handleCancel,
  IndividualDadataByIdAndDate,
}) => {
  const schema = yup
    .object({
      expectedCashByDA: yup.string().required("This Field is Required"),
      cashDepositByDA: yup.string().required("This Field is Required"),
      remark: yup.string(),
    })
    .required();

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      expectedCashByDA: selectedDaData?.totalAmount || "",
      cashDepositByDA: "",
      remark: "",
    },
    validationSchema: schema,
    onSubmit,
  });
  async function onSubmit(values, actions) {
    // console.log(values);
    const data = {
      orderId: selectedDaData?.orderId,
      depositedAmount: values.cashDepositByDA,
      remark: values.remark,
      depositedBy: selectedDaData?.assignPersonId,
    };
    // console.log(data);
    try {
      const res = await UpdateDepositAmountByDA(data);
      if (res) {
        handleCancel();
        resetForm();
        setFieldValue("cashDepositByDA", "");
        setFieldValue("remark", "");
        IndividualDadataByIdAndDate();
      }
    } catch (error) {
      console.log(error.message);
      message.error("Failed to update Something went wrong");
    }
  }

  useEffect(() => {
    if (selectedDaData) {
      setFieldValue("expectedCashByDA", selectedDaData.totalAmount);
    }
  }, [selectedDaData, setFieldValue]);
  return (
    <div>
      <form>
        <Modal
          title={
            <p
              style={{
                textAlign: "center",
                fontSize: "20px",
                margin: 0,
                padding: 0,
              }}
            >
              Cash Reconcile
            </p>
          }
          open={isModalOpen}
          okText="Submit"
          cancelText="Cancel"
          onOk={handleSubmit}
          onCancel={() => {
            handleCancel();
          }}
          //   footer={}
        >
          <Paragraph style={{ textAlign: "center" }}>
            <span style={{ margin: 0, fontWeight: 600 }}> Order Id:</span>
            {selectedDaData?.orderId}
          </Paragraph>

          <div
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            <Form.Item
              label="Expected Cash"
              layout="vertical"
              validateStatus={
                errors.expectedCashByDA && touched.expectedCashByDA
                  ? "error"
                  : ""
              }
              help={
                errors.expectedCashByDA && touched.expectedCashByDA
                  ? errors.expectedCashByDA
                  : null
              }
              style={{ fontWeight: 500 }}
            >
              <Input
                id="expectedCashByDA"
                name="expectedCashByDA"
                value={values.expectedCashByDA}
                onBlur={handleBlur}
                placeholder="Total Amount"
              />
            </Form.Item>

            <Form.Item
              label="Cash Deposited By DA"
              layout="vertical"
              validateStatus={
                errors.cashDepositByDA && touched.cashDepositByDA ? "error" : ""
              }
              help={
                errors.cashDepositByDA && touched.cashDepositByDA
                  ? errors.cashDepositByDA
                  : null
              }
              style={{ fontWeight: 500 }}
            >
              <Input
                id="cashDepositByDA"
                name="cashDepositByDA"
                placeholder="Cash By DA"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>

            <Form.Item
              label="Remarks"
              layout="vertical"
              validateStatus={errors.remark && touched.remark ? "error" : ""}
              help={errors.remark && touched.remark ? errors.remark : null}
              style={{ fontWeight: 500 }}
            >
              <Input
                id="remark"
                name="remark"
                placeholder="Remark"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>
          </div>
        </Modal>
      </form>
    </div>
  );
};

export default CashReconcileModal;
