import { Segmented } from "antd";
import React, { useState } from "react";
import AssignDA from "./AssignDA";
import ReAssignDA from "./ReAssignDA";

const AssignReAssignTab = () => {
  const [segmentValue, setSegmentValue] = useState("Assign");
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Segmented
          options={["Assign", "ReAssign"]}
          onChange={(value) => {
            console.log(value);
            setSegmentValue(value);
          }}
        />
      </div>
      {segmentValue === "Assign" && <AssignDA />}
      {segmentValue === "ReAssign" && <ReAssignDA />}
    </div>
  );
};

export default AssignReAssignTab;
