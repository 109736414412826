import React, { useContext } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "../Login/Login";
import DeliveryRequest from "../Pages/DeliveryRequest/DeliveryRequest";
import ProtectedRoute from "../ProtectedRoute";
import Sidebar from "../components/Sidebar/Sidebar";
import { LoadingContext } from "../components/Loader/LoadingContext";
import Loader from "../components/Loader/Loader";
import OrderStatus from "../Pages/OrderStatus/OrderStatus";
import RunSheetClosure from "../Pages/RunSheetClosure/RunSheetClosure";
import DaCashReconciliation from "../Pages/CashReconciliation/DaCashReconciliation";
import IndividualCashReconcileDataByDA from "../Pages/CashReconciliation/IndividualCashReconcileDataByDA";
import OrderList from "../Pages/DA_Screens/OrderList";
import OrderDetails from "../Pages/DA_Screens/OrderDetails";
import CashBackList from "../Pages/Cashback/CashBackList";
import AssignReAssignTab from "../Pages/AssignOrReAssignToDA/AssignReAssignTab";
import HomePage from "../Pages/HomePage";
import BannerImagesList from "../Pages/BannerImages/BannerImagesList";
import PrivacyPolicy from "../Pages/Home-components/Privacypolicy/PrivacyPolicy";

const Layout = ({ children }) => {
  const location = useLocation();
  const noSidebarRoutes = ["/", "/login", "/contact-us", "/privacyPolicy"];
  const showSidebar = !noSidebarRoutes.includes(location.pathname);
  const noMarginTop = ["/", "/privacyPolicy"];
  const marginRemove = !noMarginTop.includes(location.pathname);

  const { loading } = useContext(LoadingContext);
  return (
    <div className={`${marginRemove ? "layout-Maindiv" : "no-margin"}`}>
      {showSidebar && <Sidebar />}
      <div className={showSidebar ? "main-content" : "full-width-content"}>
        {children}
      </div>
      <Loader loading={loading} />
    </div>
  );
};

const AllRoutes = () => {
  return (
    <Layout>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route exact path="/login" element={<Login />} />
        <Route
          exact
          path="/deliveryRequest"
          element={
            <ProtectedRoute>
              <DeliveryRequest />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/assignDA"
          element={
            <ProtectedRoute>
              <AssignReAssignTab />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/orderStatus"
          element={
            <ProtectedRoute>
              <OrderStatus />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/runSheet"
          element={
            <ProtectedRoute>
              <RunSheetClosure />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/DaCashReconciliation"
          element={
            <ProtectedRoute>
              <DaCashReconciliation />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/individualDaCashReconcile/:id/:name"
          element={
            <ProtectedRoute>
              <IndividualCashReconcileDataByDA />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/cashbackList"
          element={
            <ProtectedRoute>
              <CashBackList />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/bannerImages"
          element={
            <ProtectedRoute>
              <BannerImagesList />
            </ProtectedRoute>
          }
        />
        {/* Add more routes here */}

        {/* ***********************************DA Routes******************************* */}
        <Route
          exact
          path="/orderList"
          element={
            <ProtectedRoute>
              <OrderList />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/orderList/:orderId"
          element={
            <ProtectedRoute>
              <OrderDetails />
            </ProtectedRoute>
          }
        />
        {/* Add more routes here */}
      </Routes>
    </Layout>
  );
};

export default AllRoutes;
