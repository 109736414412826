import {
  Button,
  Card,
  Col,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  Upload,
  message,
} from "antd";
import React from "react";
import { useEffect } from "react";
import Compressor from "compressorjs";
import { useState } from "react";
import {
  b2corderdetails,
  cancelorder,
  orderdadelivery,
  orderdapickup,
  orderreachlocation,
} from "../../Services/Api/ApiFile";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import { MenuProps } from "antd";
import { Dropdown, Space } from "antd";
import "./uploadButton.css";
const { Paragraph } = Typography;

const OrderDetails = () => {
  const navigate = useNavigate();
  const [pickupImage, setPickupImage] = useState();
  console.log("pickupImage: ", pickupImage);
  const [deliveryImage, setDeliveryImage] = useState(null);
  console.log("deliveryImage: ", deliveryImage);
  const [payment, setPayment] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalTwoOpen, setIsModalTwoOpen] = useState(false);
  const [packet, setPacket] = useState();
  const [reason, setReason] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const { orderId } = useParams();
  const Option = Select.Option;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setPayment();
    setIsModalOpen(false);
  };

  const showTwoModal = () => {
    setIsModalTwoOpen(true);
  };

  const submitRejection = async () => {
    if (!reason) {
      message.info("Please select reason for delivery rejection!");
      return;
    }
    const values = {
      orderId: orderId,
      stationCode: "",
      managerUserId: "",
      cancelreason: reason,
      cancelBy: localStorage.getItem("username"),
      cancelType: "DA",
    };

    try {
      const { data } = await cancelorder(values);
      if (data) {
        console.log("data: ", data);
        navigate("/orderList");
      }
    } catch (e) {}
  };

  const handleTwoOk = () => {
    submitRejection();
  };

  const handleTwoCancel = () => {
    setReason();
    setIsModalTwoOpen(false);
  };
  const compressFile = (e, state) => {
    const file = e;
    console.log("file: ", file);

    if (!file) {
      return;
    }

    new Compressor(file, {
      quality: 0.4,

      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        console.log(">>>>>>>", result);
        state(result);

        // Simply Print the Base64 Encoded String,
        // without additional data: Attributes.
        //   console.log('Base64 String without Tags- ',
        //  base64String.substr(base64String.indexOf(', ') + 1));
        //   state(result)
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  const handleMenuClick = (e) => {
    setReason(e.key);
  };
  const handleMenuClick2 = (value) => {
    setPaymentMethod(value);
  };

  const items = [
    {
      key: "Customer Not Available",
      label: "Customer Not Available",
    },
    {
      key: "Customer Unable To Pay",
      label: "Customer Unable To Pay",
    },
    {
      key: "Customer Rejected The Package",
      label: "Customer Rejected The Package",
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  // const props: UploadProps = {
  //     name: 'file',
  //     action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
  //     headers: {
  //       authorization: 'authorization-text',
  //     },
  //     onChange(info) {
  //       if (info.file.status !== 'uploading') {
  //         console.log(info.file, info.fileList);
  //       }
  //       if (info.file.status === 'done') {
  //         message.success(`${info.file.name} file uploaded successfully`);
  //       } else if (info.file.status === 'error') {
  //         message.error(`${info.file.name} file upload failed.`);
  //       }
  //     },
  //   };

  const [status, setStatus] = React.useState();
  const getDetails = async () => {
    const { data } = await b2corderdetails(orderId);
    console.log("data: ", data);
    if (data) {
      setStatus(data?.deliverystatus);
      setPacket(data);
    }
  };

  const reachedLocation = async () => {
    const { data } = await orderreachlocation(orderId);
    console.log("data: ", data);
    if (data) {
      console.log("data: ", data);

      getDetails();
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  const pickupDone = async () => {
    console.log("pickupImage: ", pickupImage);
    try {
      const formdata = new FormData();
      let formValues = {};
      formValues.orderId = orderId;

      for (var key in formValues) {
        formdata.append(key, formValues[key]);
      }
      formdata.append("pickupImage", pickupImage);
      console.log("khsdbfkghdsbfkdbs", formdata.get("pickupImage"));

      const { data } = await orderdapickup(formdata);
      if (data) {
        console.log("data: ", data);
        getDetails();
      }
    } catch (e) {}
  };
  const error = () => {
    toast.error("Some Error Occoured, Please try again later", {
      position: "top-right",
    });
  };

  const deliveryDone = async () => {
    const formdata = new FormData();
    formdata.append("orderId", orderId);
    formdata.append("paymentType", paymentMethod);
    formdata.append("collectedAmount", payment);
    formdata.append("deliveryImage", deliveryImage);
    try {
      const { data } = await orderdadelivery(formdata);
      console.log("data: ", data);
      if (data === "Success") {
        navigate("/orderList");
      }
    } catch (e) {
      error();
    }
  };

  return (
    <>
      <Typography.Title level={3} className="heading">
        Order Details
      </Typography.Title>
      {status === "Assigned" && (
        <Card style={{ marginTop: "10px" }}>
          <Row gutter={[16, 16]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paragraph style={{ fontWeight: 600, fontSize: "15px" }}>
                Customer Name:
              </Paragraph>
              <Paragraph style={{ fontWeight: 400 }}>
                {packet?.pickupName}
              </Paragraph>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paragraph style={{ fontWeight: 600, fontSize: "15px" }}>
                Pickup Slot:
              </Paragraph>
              <Paragraph style={{ fontWeight: 400 }}>
                {packet?.pickupSlot}
              </Paragraph>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paragraph style={{ fontWeight: 600, fontSize: "15px" }}>
                Pickup Address:
              </Paragraph>
              <Paragraph style={{ fontWeight: 400 }}>
                {packet?.pickupAddress}
              </Paragraph>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "center" }}
            >
              <Button
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  borderRadius: "8px",
                }}
                //   loading={btnLoading}
                //   iconPosition={"start"}
                //   onClick={() => AssignDA(el.orderId)}
              >
                <a
                  href={`google.navigation:q=${packet?.pickupCoordinatesLatitude}+${packet?.pickupCoordinatesLongitude}`}
                >
                  Take Me There
                </a>
              </Button>
            </Col>

            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "center" }}
            >
              <Button
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  borderRadius: "8px",
                }}
                //   loading={btnLoading}
                iconPosition={"start"}
                //   onClick={() => AssignDA(el.orderId)}
              >
                <a href={`tel:${packet?.pickupNumber}`}>Call</a>
              </Button>
            </Col>
          </Row>
          <Col span={24}>
            <Button
              style={{ width: "100%", borderRadius: "8px" }}
              //   loading={btnLoading}
              iconPosition={"start"}
              onClick={() => reachedLocation()}
            >
              Reached Pickup Location
            </Button>
          </Col>
        </Card>
      )}
      {status === "LocationReached" && (
        <Card style={{ marginTop: "10px" }}>
          <Row>
            <Col
              span={24}
              style={{
                textAlign: "center",
                marginTop: "50%",
                marginBottom: "10px",
              }}
            >
              {/* <Upload
              //  {...props}
              name="avatar"
              //  onChange={(info)=>{
              //     console.log("INFO", info);
              // }}
              beforeUpload={file=> {
                console.log('file: ', file);
                // Access file content here and do something with it
                const reader = new FileReader();
                reader.onload = e => {
                  console.log('e: ', e);
                    console.log(">>>>>>>",e.target.result);
                    compressFile(e.target.result, setPickupImage);
                  }

                // Prevent upload
                return false;
              }}
              accept="image/x-png,image/jpeg"
              capture="environment"
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload> */}
              <div class="upload-btn-wrapper">
                <button class="btn">
                  {pickupImage ? "Uploaded" : "Upload a file"}

                  <input
                    type="file"
                    name="picture"
                    accept="image/*"
                    capture="user"
                    onChange={(e) => {
                      compressFile(e.target.files[0], setPickupImage);
                      // setPickupImage(e.target.files);
                    }}
                  />
                </button>
              </div>
            </Col>
            <Col span={24}>
              <Button
                style={{ width: "100%", borderRadius: "8px" }}
                //   loading={btnLoading}

                disabled={pickupImage ? false : true}
                iconPosition={"start"}
                onClick={() => pickupDone()}
              >
                Picked Up
              </Button>
            </Col>
          </Row>
        </Card>
      )}

      {status === "Pickup" && (
        <Card style={{ marginTop: "10px" }}>
          <Row gutter={[16, 16]}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paragraph style={{ fontWeight: 600, fontSize: "15px" }}>
                Customer Name:
              </Paragraph>
              <Paragraph style={{ fontWeight: 400 }}>
                {packet?.dropName}
              </Paragraph>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paragraph style={{ fontWeight: 600, fontSize: "15px" }}>
                Delivery Slot:
              </Paragraph>
              <Paragraph style={{ marginBottom: "10px", fontWeight: 400 }}>
                {packet?.deliveryTimeSlot}
              </Paragraph>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paragraph style={{ fontWeight: 600, fontSize: "15px" }}>
                Delivery Address:
              </Paragraph>
              <Paragraph style={{ marginBottom: "10px", fontWeight: 400 }}>
                {packet?.dropAddress}
              </Paragraph>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "center" }}
            >
              <Button
                //   primaryColor="#FF6A1A"
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  borderRadius: "8px",
                }}
                //   loading={btnLoading}
                iconPosition={"start"}
                //   onClick={() => AssignDA(el.orderId)}
              >
                <a
                  href={`google.navigation:q=${packet?.dropCoordinatesLatitude}+${packet?.dropCoordinatesLongitude}`}
                >
                  Take Me There
                </a>
              </Button>
            </Col>

            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: "center" }}
            >
              <Button
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  borderRadius: "8px",
                }}
                //   loading={btnLoading}
                iconPosition={"start"}
                //   onClick={() => AssignDA(el.orderId)}
              >
                <a href={`tel:${packet?.dropNumber}`}>Call</a>
              </Button>
            </Col>
          </Row>
          <Col span={24}>
            <Button
              style={{ width: "100%", borderRadius: "8px", marginBottom: 10 }}
              //   loading={btnLoading}
              iconPosition={"start"}
              onClick={showModal}
              //   onClick={() => AssignDA(el.orderId)}
            >
              Collect Payment
            </Button>
          </Col>
          <Col span={24}>
            <Button
              style={{ width: "100%", borderRadius: "8px", marginBottom: 10 }}
              //   loading={btnLoading}
              iconPosition={"start"}
              onClick={showTwoModal}
              //   onClick={() => AssignDA(el.orderId)}
            >
              Reject Delivery
            </Button>
          </Col>
          <Col span={24} style={{ textAlign: "center", marginBottom: "10px" }}>
            {/* <Upload
                 {...props}
                name="avatar"
                 onChange={(info)=>{
                    console.log("INFO", info);
                }}
                beforeUpload={(file, fileList) => {
                Access file content here and do something with it
                console.log(file);
                compressFile(file, setDeliveryImage);

                Prevent upload
                  return false;
                }}
                onChange={(info) => {
                  console.log(info, "info");
                }}
                accept="image"
                capture="environment"
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload> */}
            {/* <Upload
                name="avatar"
                accept="image/*"
                capture="environment"
                beforeUpload={(file) => {
                  console.log(file);
                  setDeliveryImage(file);
                }}
                showUploadList={true} // Hide the default upload list
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload> */}

            <div class="upload-btn-wrapper">
              <button class="btn">
                {deliveryImage ? "Uploaded" : "Upload a file"}

                <input
                  type="file"
                  name="picture"
                  accept="image/*"
                  capture="user"
                  onChange={(e) => {
                    compressFile(e.target.files[0], setDeliveryImage);
                    console.log(e.target.files[0]);
                    // setDeliveryImage(e.target.files[0]);
                  }}
                />
              </button>
            </div>
          </Col>
          <Col span={24}>
            <Button
              style={{ width: "100%", borderRadius: "8px" }}
              //   loading={btnLoading}
              iconPosition={"start"}
              disabled={payment && deliveryImage ? false : true}
              onClick={() => deliveryDone()}
            >
              Delivered
            </Button>
          </Col>
        </Card>
      )}
      <Modal
        title="Collect Payment"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Typography.Text style={{ marginBottom: 10, fontWeight: 600 }}>
          Expected Cash :
        </Typography.Text>
        <InputNumber
          addonBefore="₹"
          defaultValue={packet?.totalAmount}
          disabled
        />
        <Typography.Text style={{ marginBottom: 10, fontWeight: 600 }}>
          Cash Deposited :
        </Typography.Text>
        <InputNumber
          addonBefore="₹"
          defaultValue={0}
          onChange={(value) => {
            setPayment(value);
          }}
        />
        <br />
        <br />
        <Typography.Text style={{ marginBottom: 10, fontWeight: 600 }}>
          Payment Method:{" "}
        </Typography.Text>

        <Select
          defaultValue="Select Payment Method"
          style={{ width: "50%" }}
          onChange={handleMenuClick2}
        >
          <Option value="UPI">UPI</Option>
          <Option value="Cash">Cash</Option>
        </Select>
      </Modal>
      <Modal
        title="Reject Delivery"
        open={isModalTwoOpen}
        onOk={handleTwoOk}
        onCancel={handleTwoCancel}
      >
        <Typography.Text style={{ marginBottom: 10, fontWeight: 600 }}>
          Cancel Reason
        </Typography.Text>
        <br />

        <Dropdown menu={menuProps}>
          <Button style={{ width: "100%" }}>
            <Space>
              {reason ? reason : "Select Cancel Reason"}
              {/* <DownOutlined  /> */}
            </Space>
          </Button>
        </Dropdown>
      </Modal>
    </>
  );
};

export default OrderDetails;
