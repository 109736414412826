import { Col, Empty, Image, Row, Typography, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { LoadingContext } from "../../components/Loader/LoadingContext";
import moment from "moment";
import ThreeWheeler from "../../images/three wheeler 1.png";
import TwoWheeler from "../../images/motorcycle 1.png";
import FourWheeler from "../../images/express-delivery 1.png";
import { b2cstaffdateorder, b2cstafforder } from "../../Services/Api/ApiFile";
import { useNavigate } from "react-router-dom";

const OrderList = () => {
  const navigate = useNavigate();
  const [deliveryRequest, setDeliveryRequest] = useState([]);
  const { startLoading, stopLoading } = useContext(LoadingContext);

  const formattedDate = (date) => {
    const dob = moment(date);
    const formattedDob = dob.format("DD-MM-YYYY");
    return formattedDob;
  };

  const GetDAOrders = async () => {
    try {
      startLoading();
      const res = await b2cstaffdateorder(
        moment(new Date()).format("YYYY-MM-DD"),
        JSON.parse(localStorage.getItem("token")).userId
      );
      // console.log("delivrydata", res);
      setDeliveryRequest(res.data);
    } catch (err) {
      console.log(err.message);
    } finally {
      stopLoading();
    }
  };
  useEffect(() => {
    GetDAOrders();
  }, []);

  return (
    <>
      <div>
        <Typography.Title level={3} className="heading">
          Order List
        </Typography.Title>

        <div className="deliveryReq-mainBox">
          {deliveryRequest.length === 0 ? (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 60,
              }}
              description={<Typography.Text>No Data Found</Typography.Text>}
            />
          ) : (
            deliveryRequest.map((el, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    console.log("row", el);
                    el?.deliverystatus === "Delivered" ||
                    el?.deliverystatus === "Cancelled"
                      ? message.info(
                          `This Packet is already ${el?.deliverystatus}`
                        )
                      : navigate(`/orderList/${el.orderId}`);
                  }}
                >
                  <Row
                    style={{
                      backgroundColor:
                        el?.deliverystatus === "Delivered"
                          ? "rgba(0, 201, 76, 0.12)"
                          : el?.deliverystatus === "Cancelled"
                          ? "rgba(78, 78, 78, 0.12)"
                          : "rgba(244, 109, 37, 0.12)",

                      borderRadius: "5px",
                      marginTop: index === 0 ? 0 : "10px",
                      padding: "10px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Typography.Text style={{ margin: 0, fontWeight: 600 }}>
                        {el?.deliverystatus === "Pickup"
                          ? el.dropName
                          : el.pickupName}{" "}
                        ({el.orderId})
                      </Typography.Text>
                    </Col>
                    {/* <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                   
                      <Typography.Text style={{ margin: 0, fontWeight: 600 }}>
                        Delivery Date
                      </Typography.Text>
                      <Typography.Text style={{ margin: 0 }}>
                        {formattedDate(el.deliveryDate)}
                      </Typography.Text>
                      <Typography.Text style={{ margin: 0, fontWeight: 600 }}>
                        Delivery Slot
                      </Typography.Text>
                      <Typography.Text style={{ margin: 0 }}>
                        {el.deliveryTimeSlot}
                      </Typography.Text>
                    </Col> */}
                    <Col xs={18} sm={18} md={6} lg={6} xl={6}>
                      <Typography.Text style={{ margin: 0, fontWeight: 600 }}>
                        Pickup Date:{" "}
                      </Typography.Text>

                      <Typography.Text style={{ margin: 0 }}>
                        {formattedDate(el.pickupDate)}
                      </Typography.Text>
                      <br />
                      <Typography.Text style={{ margin: 0, fontWeight: 600 }}>
                        Pickup Slot:{" "}
                      </Typography.Text>
                      <Typography.Text style={{ margin: 0 }}>
                        {el.pickupSlot}
                      </Typography.Text>
                      <br />
                      <Typography.Text style={{ margin: 0 }}>
                        {el.pickupAddress}
                      </Typography.Text>
                      <br />
                    </Col>
                    {/* <Col
                      className="nameAndNumber"
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                        Weight
                      </Paragraph>
                      <Paragraph style={{ margin: 0 }}>
                        {el.weight} {el.units}
                      </Paragraph>
                      <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                        Customer Number
                      </Paragraph>
                      <Paragraph style={{ margin: 0 }}>
                        {el.customerMobileNo}
                      </Paragraph>
                    </Col> */}
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                      {/* <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                      Customer Number
                    </Paragraph>
                    <Paragraph style={{ margin: 0 }}>
                      {el.customerMobileNo}
                    </Paragraph> */}
                      {el.vehicleType === "Three Wheeler" && (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Image
                            src={ThreeWheeler}
                            alt="icon"
                            preview={false}
                          />
                        </div>
                      )}
                      {el.vehicleType === "Two Wheeler" && (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Image src={TwoWheeler} alt="icon" preview={false} />
                        </div>
                      )}
                      {el.vehicleType === "Four Wheeler" && (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Image src={FourWheeler} alt="icon" preview={false} />
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
};

export default OrderList;
