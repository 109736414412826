import {
  Button,
  Card,
  DatePicker,
  Form,
  Modal,
  Select,
  Table,
  Typography,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import "./RunSheet.scss";
import { EditOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import {
  ChangeDeliveryStatusOfManager,
  GetDefaultRunSheetList,
  GetRunSheetListWithDate,
} from "../../Services/Api/ApiFile";
import { json2csv } from "json-2-csv";
import FileSaver from "file-saver";
import { LoadingContext } from "../../components/Loader/LoadingContext";
import { GrDocumentCsv } from "react-icons/gr";
const { Option } = Select;

const RunSheetClosure = () => {
  const [runSheetData, setRunSheetData] = useState([]);
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectDate, setSelectDate] = useState(null);
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const dateFormatList = ["DD-MM-YYYY", "DD-MM-YY", "DD-MM-YYYY", "DD-MM-YY"];

  const columns = [
    {
      title: "DA",
      dataIndex: "assignPersonName",
      key: "assignPersonName",
      responsive: ["xs", "sm", "md", "lg", "xl"],
    },
    {
      title: "Customer Name",
      dataIndex: "dropName",
      key: "dropName",
      responsive: ["md", "lg", "xl"],
    },
    {
      title: "Assign At",
      dataIndex: "assignAt",
      key: "assignAt",
      responsive: ["xs", "sm", "md", "lg", "xl"],
      render: (text, record) => (
        <div>{text ? dayjs(text).format("DD-MM-YYYY") : "N.A"}</div>
      ),
    },
    {
      title: "Booking Id",
      dataIndex: "orderId",
      key: "orderId",
      responsive: ["md", "lg", "xl"],
    },
    {
      title: "Address",
      dataIndex: "pickupAddress",
      key: "pickupAddress",
      responsive: ["lg", "xl"],
      width: 350,
      render: (text) => (
        <div
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "wrap",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Order Name",
      dataIndex: "packetInfo",
      key: "packetInfo",
      responsive: ["md", "lg", "xl"],
    },
    {
      title: "Status by DA",
      dataIndex: "deliverystatus",
      key: "deliverystatus",
      responsive: ["xs", "sm", "md", "lg", "xl"],
    },
    {
      title: "Status by Manager",
      dataIndex: "managerStatus",
      key: "managerStatus",
      responsive: ["xs", "sm", "md", "lg", "xl"],
      render: (text, record) => (
        <div>
          {text === null ? "N.A" : text}{" "}
          {record?.managerStatus !== "Delivered" && (
            <Button type="link" onClick={() => showModal(record)}>
              <EditOutlined />
            </Button>
          )}
        </div>
      ),
    },
  ];

  const schema = Yup.object().shape({
    managerStatus: Yup.string().required("Manager status is required"),
  });

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      managerStatus: runSheetData?.managerUserName || "",
    },
    validationSchema: schema,
    onSubmit,
  });

  async function onSubmit(values, actions) {
    // console.log(values);
    const submitData = {
      orderId: selectedDelivery.orderId,
      managerStatus: values.managerStatus,
    };
    // console.log(submitData);
    try {
      startLoading();
      await ChangeDeliveryStatusOfManager(submitData);
    } catch (error) {
      console.log(error.message);
    } finally {
      stopLoading();
      resetForm();
      setIsModalVisible(false);
      GetRunSheetList();
    }
  }

  const showModal = (record) => {
    // console.log("selected", record);
    setSelectedDelivery(record);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const GetRunSheetList = async () => {
    try {
      startLoading();
      if (selectDate) {
        const res = await GetRunSheetListWithDate(selectDate);
        setRunSheetData(res.data);
      } else {
        const res = await GetDefaultRunSheetList();
        const sortedRunsheetData = res.data.sort(
          (a, b) => new Date(b.assignAt) - new Date(a.assignAt)
        );
        // console.log("Sorted data:", sortedRunsheetData);
        setRunSheetData(res.data);
      }
    } catch (error) {
      console.log(error.message);
      message.error(error.message);
    } finally {
      stopLoading();
    }
  };

  const handleDownloadCSV = async () => {
    const dataToConvert = runSheetData.map((el) => ({
      PacketInfo: el.packetInfo,
      OrderId: el.orderId,
      DeliveryDate: dayjs(el.deliveryDate).format("DD-MM-YYYY"),
      DeliveryTimeSlot: el.deliveryTimeSlot,
      PickupDate: dayjs(el.pickupDate).format("DD-MM-YYYY"),
      PickupSlot: el.pickupSlot,
      Weight: el.weight,
      Units: el.units,
      CustomerMobileNumber: el.customerMobileNo,
      VehicleType: el.vehicleType,
      VendorCode: el.vendorCode,
      TotalAmount: el.totalAmount,
      DeliveryStatus: el.deliverystatus,
      ManagerStatus: el.managerStatus,
      ManagerUserName: el.managerUserName,
      AssignPersonName: el.assignPersonName,
      DepositedAmount: el.depositedAmount,
      Remark: el.remark,
    }));

    const options = {
      keys: [
        "PacketInfo",
        "OrderId",
        "DeliveryDate",
        "DeliveryTimeSlot",
        "PickupDate",
        "PickupSlot",
        "Weight",
        "Units",
        "CustomerMobileNumber",
        "VehicleType",
        "VendorCode",
        "TotalAmount",
        "DepositedAmount",
        "AssignPersonName",
        "ManagerUserName",
        "DeliveryStatus",
        "ManagerStatus",
        "Remark",
      ],
      delimiter: {
        wrap: '"', // Optional wrapping of values
        field: ",", // Field delimiter
        array: ";", // Array value delimiter
      },
    };

    try {
      const csv = json2csv(dataToConvert, options);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      FileSaver.saveAs(blob, "runSheetClosure.csv");
    } catch (error) {
      console.error("Error generating CSV:", error);
    }
  };

  useEffect(() => {
    GetRunSheetList();
  }, [selectDate]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Typography.Title level={3} className="heading">
          Run Sheet Closure
        </Typography.Title>

        <DatePicker
          id="SelectDate"
          name="SelectDate"
          // value={dayjs(selectDate)}
          onChange={(date) => {
            if (date) {
              setSelectDate(dayjs(date).format("YYYY-MM-DD"));
            } else {
              setSelectDate("");
            }
          }}
          placeholder="Selected Date"
          format={dateFormatList}
        />

        <Button className="downloadCSVbtn" onClick={handleDownloadCSV}>
          Download
          <GrDocumentCsv style={{ fontSize: "20px" }} />
        </Button>
      </div>
      <div className="runsheet-mainBox" style={{ marginTop: "10px" }}>
        <Card>
          <Table
            columns={columns}
            dataSource={runSheetData}
            rowKey="id"
            pagination={{ pageSize: 6 }}
            className="runsheet-table"
            //   onRow={(record) => ({
            //     onClick: () => {
            //       console.log(record);
            //     },
            //   })}
          />
        </Card>
        <Modal
          title="Update Delivery Status of Manager"
          open={isModalVisible}
          onOk={handleSubmit}
          onCancel={handleCancel}
          okText="Save"
        >
          <Typography.Title level={5}>
            OrderId:{selectedDelivery?.orderId}
          </Typography.Title>
          <form onSubmit={handleSubmit}>
            <Form.Item
              label="Manager Status"
              validateStatus={
                errors.managerStatus && touched.managerStatus ? "error" : ""
              }
              help={
                errors.managerStatus && touched.managerStatus
                  ? errors.managerStatus
                  : ""
              }
            >
              <Select
                name="managerStatus"
                value={values.managerStatus}
                onChange={(value) => setFieldValue("managerStatus", value)}
              >
                <Option value="Delivered">Delivered</Option>
                <Option value="Cancelled">Cancelled</Option>
                <Option value="RTO">Return To Origin(RTO)</Option>
              </Select>
            </Form.Item>
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default RunSheetClosure;
