import {
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Typography,
} from "antd";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import * as yup from "yup";
import moment from "moment";
import dayjs from "dayjs";
import TwoWheeler from "../../images/motorcycle 1.png";
import ThreeWheeler from "../../images/three wheeler 1.png";
import FourWheeler from "../../images/express-delivery 1.png";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { QuestionCircleOutlined } from "@ant-design/icons";
import "./DeliveryRequest.scss";
import {
  AcceptTheDelivery,
  CancelTheDelivery,
} from "../../Services/Api/ApiFile";
import { toast } from "react-toastify";
dayjs.extend(customParseFormat);
const { Paragraph } = Typography;

const IndividualProductModal = ({
  isModalOpen,
  handleCancel,
  selectedDelivery,
  setIsModalOpen,
  GetDeliveryRequests,
}) => {
  const schema = yup
    .object({
      packetInfo: yup.string().required("This Field is Required"),
      customerMobileNo: yup.string().required("This Field is Required"),
      pickupSlot: yup.string().required("This Field is Required"),
      pickupDate: yup.string().required("This Field is Required"),
      deliveryDate: yup.date().required("This Field is Required"),
      deliverySlot: yup.string().required("This Field is Required"),
      totalAmount: yup.number().required("This Field is Required"),
      weight: yup.string().required("This Field is Required"),
      vehicleType: yup.string().required("This Field is Required"),
    })
    .required();

  const userName = localStorage.getItem("username");
  const token = JSON.parse(localStorage.getItem("token"));

  const dateFormatList = ["DD-MM-YYYY", "DD-MM-YY", "DD-MM-YYYY", "DD-MM-YY"];
  const vehicleTypes = [
    { type: "Two Wheeler", img: TwoWheeler, label: "Two Wheeler" },
    {
      type: "Three Wheeler",
      img: ThreeWheeler,
      label: "Three Wheeler",
    },
    {
      type: "Four Wheeler",
      img: FourWheeler,
      label: "Four Wheeler",
    },
  ];

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      packetInfo: selectedDelivery?.packetInfo || "",
      customerMobileNo: selectedDelivery?.customerMobileNo || "",
      pickupSlot: selectedDelivery?.pickupSlot || "",
      pickupDate: selectedDelivery?.pickupdate
        ? dayjs(selectedDelivery.pickupdate)
        : null,
      deliveryDate: selectedDelivery?.deliveryDate
        ? dayjs(selectedDelivery.deliveryDate)
        : null,
      deliverySlot: selectedDelivery?.deliveryTimeSlot || "",
      totalAmount: selectedDelivery?.totalAmount || "",
      weight: selectedDelivery?.weight || "",
      vehicleType: selectedDelivery?.vehicleType || "",
      cancelReason: "",
    },
    validationSchema: schema,
    onSubmit,
  });

  async function onSubmit(values, actions) {
    // const data = {
    //   ...values,
    //   orderId: selectedDelivery.orderId,
    //   id: selectedDelivery.id,
    //   pickupDate: dayjs(values.pickupDate).format("DD-MM-YYYY"),
    //   deliveryDate: dayjs(values.deliveryDate).format("DD-MM-YYYY"),
    //   units: selectedDelivery.units,
    // };

    const ApproveSubmitData = {
      orderId: selectedDelivery.orderId,
      pickupSlot: values.pickupSlot,
      deliveryTimeSlot: values.deliverySlot,
      vehicleType: values.vehicleType,
      managerUserId: token.userId,
      managerUserName: userName,
      stationCode: token.stationCode,
      totalAmount: values.totalAmount,
    };

    // console.log("approve data", ApproveSubmitData);

    try {
      const res = await AcceptTheDelivery(ApproveSubmitData);
      // const res = true;
      if (res) {
        toast.success("Data Submitted successfully");
        setIsModalOpen(false);
        GetDeliveryRequests();
        resetForm();
      } else {
        throw new Error("Failed to submit data");
      }
    } catch (error) {
      toast.error(`Submission failed: ${error.message}`);
    }
  }

  // const handleVehicleClick = (type) => {
  //   setFieldValue("vehicleType", type);
  // };

  const handleReject = async () => {
    const CancelSubmitData = {
      orderId: selectedDelivery.orderId,
      cancelreason: values.cancelReason,
      cancelType: "StationManager",
      stationCode: token.stationCode,
      managerUserId: token.userId,
      cancelBy: userName,
    };
    // console.log("canceldata", CancelSubmitData);
    try {
      const res = await CancelTheDelivery(CancelSubmitData);
      if (res) {
        toast.success("Rejected successfully");
        setIsModalOpen(false);
        setFieldValue("cancelReason", "");
        resetForm();
        GetDeliveryRequests();
      } else {
        throw new Error("Failed to submit data");
      }
    } catch (error) {
      toast.error(`Submission failed: ${error.message}`);
    }
  };

  useEffect(() => {
    if (selectedDelivery) {
      setFieldValue("packetInfo", selectedDelivery.packetInfo);
      setFieldValue("customerMobileNo", selectedDelivery.customerMobileNo);
      setFieldValue("pickupSlot", selectedDelivery.pickupSlot);
      setFieldValue(
        "pickupDate",
        dayjs(selectedDelivery.pickupdate, "DD-MM-YYYY")
      );
      setFieldValue(
        "deliveryDate",
        dayjs(selectedDelivery.deliveryDate, "DD-MM-YYYY")
      );
      setFieldValue("deliverySlot", selectedDelivery.deliveryTimeSlot);
      setFieldValue("totalAmount", selectedDelivery.totalAmount);
      setFieldValue("weight", selectedDelivery.weight);
      setFieldValue("vehicleType", selectedDelivery.vehicleType);
    }
  }, [selectedDelivery, setFieldValue]);
  return (
    <div>
      <form>
        <Modal
          title={
            <p
              style={{
                textAlign: "center",
                fontSize: "20px",
                margin: 0,
                padding: 0,
              }}
            >
              Product Details
            </p>
          }
          open={isModalOpen}
          okText="Accept"
          cancelText="Reject"
          onOk={handleSubmit}
          onCancel={() => setIsModalOpen(false)}
          footer={
            <div
              style={{ display: "flex", justifyContent: "center", gap: "15px" }}
            >
              <Popconfirm
                title="Cancel the Delivery"
                onConfirm={handleReject}
                description={
                  <Form.Item
                    label="Cancel Reason"
                    layout="vertical"
                    style={{ fontWeight: 400 }}
                  >
                    <Input
                      id="cancelReason"
                      name="cancelReason"
                      value={values.cancelReason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Cancel Reason"
                    />
                  </Form.Item>
                }
                icon={
                  <QuestionCircleOutlined
                    style={{
                      color: "red",
                    }}
                  />
                }
              >
                <Button
                  danger
                  style={{ color: "white", backgroundColor: "#ff6a1a" }}
                >
                  Reject
                </Button>
              </Popconfirm>

              <button
                onClick={handleSubmit}
                style={{
                  backgroundColor: "#007aff",
                  border: "none",
                  borderRadius: "5px",
                  color: "#fff",
                  padding: "8px 15px",
                  cursor: "pointer",
                }}
              >
                Accept
              </button>
            </div>
          }
        >
          <div
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label="Packet Info"
                  layout="vertical"
                  validateStatus={
                    errors.packetInfo && touched.packetInfo ? "error" : ""
                  }
                  help={
                    errors.packetInfo && touched.packetInfo
                      ? errors.packetInfo
                      : null
                  }
                  style={{ fontWeight: 500 }}
                >
                  <Input
                    id="packetInfo"
                    name="packetInfo"
                    value={values.packetInfo}
                    onBlur={handleBlur}
                    placeholder="Packet Info"
                    readOnly={true} // if you want to edit the number remove this readonly and write handlechange
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label="Customer Number"
                  layout="vertical"
                  validateStatus={
                    errors.customerMobileNo && touched.customerMobileNo
                      ? "error"
                      : ""
                  }
                  help={
                    errors.customerMobileNo && touched.customerMobileNo
                      ? errors.customerMobileNo
                      : null
                  }
                  style={{ fontWeight: 500 }}
                >
                  <Input
                    id="customerMobileNo"
                    name="customerMobileNo"
                    placeholder="Customer Number"
                    value={values.customerMobileNo}
                    onBlur={handleBlur}
                    readOnly={true} // if you want to edit the number remove this readonly and write handlechange
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label="Pickup Date"
                  layout="vertical"
                  validateStatus={
                    errors.pickupDate && touched.pickupDate ? "error" : ""
                  }
                  help={
                    errors.pickupDate && touched.pickupDate
                      ? errors.pickupDate
                      : null
                  }
                  style={{ fontWeight: 500 }}
                >
                  <DatePicker
                    id="pickupDate"
                    name="pickupDate"
                    // value={dayjs(values.pickupDate, dateFormatList[0])}
                    value={values.pickupDate}
                    // onChange={(date) => {
                    //   setFieldValue("pickupDate", date);
                    //   console.log(date);
                    // }}
                    placeholder="Pickup Date"
                    format={dateFormatList}
                    style={{ width: "100%" }}
                    open={false} // if you want to edit date you need to remove this line uncomment the onchange
                    allowClear={false} //if you want to edit date remove this line and uncomment the onchange
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label="Pickup Slot"
                  layout="vertical"
                  validateStatus={
                    errors.pickupSlot && touched.pickupSlot ? "error" : ""
                  }
                  help={
                    errors.pickupSlot && touched.pickupSlot
                      ? errors.pickupSlot
                      : null
                  }
                  style={{ fontWeight: 500 }}
                >
                  <Select
                    name="pickupSlot"
                    id="pickupSlot"
                    defaultValue="lucy"
                    value={values.pickupSlot}
                    style={{ width: "100%" }}
                    onChange={(value) => setFieldValue("pickupSlot", value)}
                    onBlur={handleBlur}
                  >
                    {[
                      "08:00 AM - 10:00 AM",
                      "10:00 AM - 12:00 PM",
                      "12:00 PM - 02:00 PM",
                      "02:00 PM - 04:00 PM",
                      "04:00 PM - 06:00 PM",
                      "06:00 PM - 08:00 PM",
                    ].map((slot) => (
                      <Select.Option key={slot} value={slot}>
                        {slot}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label="Delivery Date"
                  layout="vertical"
                  validateStatus={
                    errors.deliveryDate && touched.deliveryDate ? "error" : ""
                  }
                  help={
                    errors.deliveryDate && touched.deliveryDate
                      ? errors.deliveryDate
                      : null
                  }
                  style={{ fontWeight: 500 }}
                >
                  <DatePicker
                    id="deliveryDate"
                    name="deliveryDate"
                    value={values.deliveryDate}
                    // onChange={(date) => {
                    //   setFieldValue("deliveryDate", date);
                    //   console.log(date);
                    // }}
                    placeholder="Delivery Date"
                    format={dateFormatList}
                    style={{ width: "100%" }}
                    open={false} // if you want to edit date you need to remove this line uncomment the onchange
                    allowClear={false} //if you want to edit date remove this line and uncomment the onchange
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label="Delivery Slot"
                  layout="vertical"
                  validateStatus={
                    errors.deliverySlot && touched.deliverySlot ? "error" : ""
                  }
                  help={
                    errors.deliverySlot && touched.deliverySlot
                      ? errors.deliverySlot
                      : null
                  }
                  style={{ fontWeight: 500 }}
                >
                  <Select
                    name="deliverySlot"
                    id="deliverySlot"
                    defaultValue="lucy"
                    value={values.deliverySlot}
                    style={{ width: "100%" }}
                    onChange={(value) => setFieldValue("deliverySlot", value)}
                    onBlur={handleBlur}
                  >
                    {[
                      "08:00 AM - 10:00 AM",
                      "10:00 AM - 12:00 PM",
                      "12:00 PM - 02:00 PM",
                      "02:00 PM - 04:00 PM",
                      "04:00 PM - 06:00 PM",
                      "06:00 PM - 08:00 PM",
                    ].map((slot) => (
                      <Select.Option key={slot} value={slot}>
                        {slot}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label="Total Amount"
                  layout="vertical"
                  validateStatus={
                    errors.totalAmount && touched.totalAmount ? "error" : ""
                  }
                  help={
                    errors.totalAmount && touched.totalAmount
                      ? errors.totalAmount
                      : null
                  }
                  style={{ fontWeight: 500 }}
                >
                  <Input
                    id="totalAmount"
                    name="totalAmount"
                    placeholder="Total Amount"
                    value={values.totalAmount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    suffix="₹"
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label="Weight"
                  layout="vertical"
                  validateStatus={
                    errors.weight && touched.weight ? "error" : ""
                  }
                  help={errors.weight && touched.weight ? errors.weight : null}
                  style={{ fontWeight: 500 }}
                >
                  <Input
                    id="weight"
                    name="weight"
                    placeholder="Weight"
                    value={values.weight}
                    onBlur={handleBlur}
                    suffix={selectedDelivery.units}
                    readOnly={true} // if you want to edit the number remove this readonly and write handlechange
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="Pickup Address"
                  layout="vertical"
                  style={{ fontWeight: 500 }}
                >
                  <Paragraph style={{ fontWeight: 400, margin: 0 }}>
                    {selectedDelivery?.pickupAddress}
                  </Paragraph>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="Drop Address"
                  layout="vertical"
                  style={{ fontWeight: 500 }}
                >
                  <Paragraph style={{ fontWeight: 400, margin: 0 }}>
                    {selectedDelivery?.dropAddress}
                  </Paragraph>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              {vehicleTypes.map((vehicle) => (
                <Col key={vehicle.type} xs={8} sm={8} md={8} lg={8} xl={8}>
                  <div
                    className={`vehicle-image-container ${
                      values.vehicleType === vehicle.type ? "selected" : ""
                    }`}
                    // onClick={() => handleVehicleClick(vehicle.type)}
                  >
                    <Image
                      src={vehicle?.img}
                      alt={vehicle.label}
                      preview={false}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Modal>
      </form>
    </div>
  );
};

export default IndividualProductModal;
