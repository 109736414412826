import { Button, Card, Input, Table, Typography, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { LoadingContext } from "../../components/Loader/LoadingContext";
import {
  GetCashbackListApi,
  UpdateCashbackPerApi,
} from "../../Services/Api/ApiFile"; // Assuming you have an update API
import { useFormik } from "formik";
import * as Yup from "yup";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { json2csv } from "json-2-csv";
import FileSaver from "file-saver";
import { GrDocumentCsv } from "react-icons/gr";

const CashBackList = () => {
  const [cashBackData, setCashbackData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [selectedRowData, setSelectedRowData] = useState({});
  const { startLoading, stopLoading } = useContext(LoadingContext);

  const schema = Yup.object().shape({
    cashbackPer: Yup.number()
      .required("Cashback percentage is required")
      .min(0, "Cashback percentage cannot be negative")
      .max(25, "Cashback percentage cannot be more than 25"),
  });

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      cashbackPer: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      // console.log(values);
      const formSubmitData = {
        cashbackPer: values.cashbackPer,
        id: selectedRowData.code,
        createdBy: selectedRowData.createdBy,
      };
      // console.log(formSubmitData);
      try {
        startLoading();
        await UpdateCashbackPerApi(formSubmitData);
        message.success("Cashback percentage updated successfully");
        setEditingKey("");
        resetForm();
        GetCashBackListData();
      } catch (error) {
        message.error("Failed to update cashback percentage");
      } finally {
        stopLoading();
      }
    },
  });

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 100,
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: 100,
    },
    {
      title: "Cashback Percentage",
      dataIndex: "cashbackPer",
      key: "cashbackPer",
      render: (text, record) => {
        const isEditing = record.id === editingKey;
        return isEditing ? (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Input
                value={values.cashbackPer}
                onChange={handleChange}
                onBlur={handleBlur}
                name="cashbackPer"
                id="cashbackPer"
                style={{ width: 100 }}
                suffix="%"
              />
              <CheckOutlined
                style={{ color: "green", marginLeft: 8 }}
                onClick={() => handleSubmit()}
              />
              <CloseOutlined
                style={{ color: "red", marginLeft: 8 }}
                onClick={() => cancel()}
              />
            </div>
            {errors.cashbackPer && touched.cashbackPer ? (
              <div style={{ color: "red" }}>{errors.cashbackPer}</div>
            ) : null}
          </>
        ) : (
          text
        );
      },
    },
    {
      title: "Action",
      key: "operation",
      render: (text, record) => (
        <Button
          onClick={() => {
            setEditingKey(record.id);
            setSelectedRowData(record);
            setFieldValue("cashbackPer", record.cashbackPer);
          }}
          disabled={record.type === "customer"}
        >
          Edit
        </Button>
      ),
    },
  ];

  const cancel = () => {
    setEditingKey("");
    resetForm();
  };

  const GetCashBackListData = async () => {
    try {
      startLoading();
      const res = await GetCashbackListApi();
      setCashbackData(res.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      stopLoading();
    }
  };

  const handleDownloadCSV = async () => {
    const dataToConvert = cashBackData.map((el) => ({
      CreatedBy: el.createdBy,
      CashbackPercentage: el.cashbackPer,
      Type: el.type,
      VendorCode: el.code,
    }));

    const options = {
      keys: ["CreatedBy", "CashbackPercentage", "Type", "VendorCode"],
      delimiter: {
        wrap: '"', // Optional wrapping of values
        field: ",", // Field delimiter
        array: ";", // Array value delimiter
      },
    };

    try {
      const csv = json2csv(dataToConvert, options);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      FileSaver.saveAs(blob, "cashbackList.csv");
    } catch (error) {
      console.error("Error generating CSV:", error);
    }
  };

  useEffect(() => {
    GetCashBackListData();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Typography.Title level={3} className="heading">
          Cashback List
        </Typography.Title>
        <Button className="downloadCSVbtn" onClick={handleDownloadCSV}>
          Download
          <GrDocumentCsv style={{ fontSize: "20px" }} />
        </Button>
      </div>
      <Card style={{ marginTop: "10px" }}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={cashBackData}
          pagination={{ pageSize: 8 }}
        />
      </Card>
    </div>
  );
};

export default CashBackList;
