import { Button, Card, Col, DatePicker, Row, Table, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./DaCashReconciliation.scss";
import dayjs from "dayjs";
import CashReconcileModal from "./CashReconcileModal";
import { GetDaIndividualDataByIdandDate } from "../../Services/Api/ApiFile";
import { LoadingContext } from "../../components/Loader/LoadingContext";
import { json2csv } from "json-2-csv";
import FileSaver from "file-saver";
import { GrDocumentCsv } from "react-icons/gr";
const { Paragraph } = Typography;

const IndividualCashReconcileDataByDA = () => {
  const { id, name } = useParams();
  const [selectDate, setSelectDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDaData, setSelectedData] = useState({});
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [daData, setDaData] = useState([]);
  const dateFormatList = ["DD-MM-YYYY", "DD-MM-YY", "DD-MM-YYYY", "DD-MM-YY"];
  const handleOpen = async (request) => {
    // console.log(" selecsdelivery", request);
    setSelectedData(request);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "Order Id",
      dataIndex: "orderId",
      key: "orderId",
      // fixed: "left",
      width: 100,
    },
    {
      title: "Assigned Date",
      dataIndex: "assignAt",
      key: "assignAt",
      width: 80,
      render: (text, record) => (
        <div>{text ? dayjs(text).format("DD-MM-YYYY") : "N.A"}</div>
      ),
    },
    {
      title: "Delivery Status",
      dataIndex: "deliverystatus",
      key: "deliverystatus",
      width: 80,
    },
    {
      title: "DA Expected Cash",
      dataIndex: "totalAmount",
      key: "totalAmount",
      width: 80,
    },

    {
      title: "DA Name",
      dataIndex: "assignPersonName",
      key: "assignPersonName",
      width: 100,
    },
    {
      title: "Amount Received By DA",
      dataIndex: "collectedAmount",
      key: "collectedAmount",
      width: 80,
    },
    {
      title: "Deposited By DA",
      dataIndex: "depositedAmount",
      key: "depositedAmount",
      width: 80,
    },

    {
      title: "DA Variance",
      dataIndex: "daVariance",
      key: "daVariance",
      width: 80,
      render: (text, record) => (
        <div>{record.collectedAmount - record.depositedAmount}</div>
      ),
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      responsive: ["xs", "sm", "md", "lg", "xl"],
      render: (text, record) => <div>{text ? text : "N.A"}</div>,
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <Button
          style={{
            whiteSpace: "normal",
            padding: "5px 10px",
            height: "50px",
          }}
          onClick={() => handleOpen(record)}
          disabled={record?.depositedAmount}
        >
          Cash Reconcile
        </Button>
      ),
    },
  ];

  const IndividualDadataByIdAndDate = async () => {
    try {
      startLoading();
      const res = await GetDaIndividualDataByIdandDate(selectDate, id);
      setDaData(res.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      stopLoading();
    }
  };

  const handleDownloadCSV = async () => {
    const dataToConvert = daData.map((data) => ({
      PacketInfo: data.packetInfo,
      OrderId: data.orderId,
      DeliveryDate: dayjs(data.deliveryDate).format("DD-MM-YYYY"),
      DeliveryTimeSlot: data.deliveryTimeSlot,
      PickupDate: dayjs(data.pickupDate).format("DD-MM-YYYY"),
      PickupSlot: data.pickupSlot,
      Weight: data.weight,
      Units: data.units,
      CustomerMobileNumber: data.customerMobileNo,
      VehicleType: data.vehicleType,
      VendorCode: data.vendorCode,
      TotalAmount: data.totalAmount,
      CollectedAmount: data.collectedAmount,
      DeliveryStatus: data.deliverystatus,
      AssignPersonName: data.assignPersonName,
      DepositedAmount: data.depositedAmount,
      Variance: data.collectedAmount - data.depositedAmount,
      Remark: data.remark,
    }));

    const options = {
      keys: [
        "PacketInfo",
        "OrderId",
        "DeliveryDate",
        "DeliveryTimeSlot",
        "PickupDate",
        "PickupSlot",
        "Weight",
        "Units",
        "CustomerMobileNumber",
        "VehicleType",
        "VendorCode",
        "TotalAmount",
        "CollectedAmount",
        "DepositedAmount",
        "Variance",
        "AssignPersonName",
        "DeliveryStatus",
        "Remark",
      ],
      delimiter: {
        wrap: '"', // Optional wrapping of values
        field: ",", // Field delimiter
        array: ";", // Array value delimiter
      },
    };

    try {
      const csv = json2csv(dataToConvert, options);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      FileSaver.saveAs(blob, "DaCashReconcileData.csv");
    } catch (error) {
      console.error("Error generating CSV:", error);
    }
  };
  useEffect(() => {
    IndividualDadataByIdAndDate();
  }, [selectDate]);

  // useEffect(() => {
  //   let filtered = DaData;

  //   if (selectDate) {
  //     filtered = filtered.filter(
  //       (item) => dayjs(item.assignDate).format("YYYY-MM-DD") === selectDate
  //     );
  //   }

  //   setFilteredData(filtered);
  // }, [selectDate]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Typography.Title level={3} className="heading">
          DA Cash Reconciliation
        </Typography.Title>
        <Button className="downloadCSVbtn" onClick={handleDownloadCSV}>
          Download
          <GrDocumentCsv style={{ fontSize: "20px" }} />
        </Button>
      </div>
      <Card style={{ marginTop: "10px" }}>
        <Card style={{ backgroundColor: "rgba(244, 109, 37, 0.12)" }}>
          <Row
            style={{
              borderRadius: "5px",
              padding: "10px",
            }}
          >
            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                DA Name
              </Paragraph>
              <Paragraph style={{ margin: 0 }}>{name}</Paragraph>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                DA ID
              </Paragraph>
              <Paragraph style={{ margin: 0 }}>{id}</Paragraph>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <Paragraph style={{ margin: 0, fontWeight: 600 }}>
                Select Date
              </Paragraph>
              <DatePicker
                id="SelectDate"
                name="SelectDate"
                defaultValue={dayjs()}
                // value={dayjs(selectDate)}
                onChange={(date) => {
                  if (date) {
                    setSelectDate(dayjs(date).format("YYYY-MM-DD"));
                  } else {
                    setSelectDate(null);
                  }
                }}
                placeholder="Select Date"
                format={dateFormatList}
              />
            </Col>
          </Row>
        </Card>
        <Table
          rowKey="orderId"
          columns={columns}
          dataSource={daData}
          pagination={{ pageSize: 6 }}
        />
      </Card>
      <CashReconcileModal
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        selectedDaData={selectedDaData}
        IndividualDadataByIdAndDate={IndividualDadataByIdAndDate}
      />
    </div>
  );
};

export default IndividualCashReconcileDataByDA;
